import { type Ctx, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import { EndorsementList, SelectCommitments } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const endorsementsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<EndorsementList>({
      url: `${BASE_URL}${API.ENDORSEMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  addService: async (id: number, service: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_ENDORSEMENT_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: {
        service
      }
    }),
  sendToDirection: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_SEND_TO_DIRECTION, { id })}`,
      method: 'PATCH',
      token: token
    }),
  validate: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_VALIDATE, { id })}`,
      method: 'PATCH',
      token: token
    }),
  getSelectCommitments: async (id: number) =>
    fetcher<SelectCommitments>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_SELECT_COMMITMENTS, { id })}`,
      method: 'GET',
      token: token
    })
})
