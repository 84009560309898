import React, { useMemo } from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { chartEngagementMonthsPalette } from 'app/helpers/color.helper'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export const PilotageDomiciliationChartComponent = function ({
  height,
  params,
  toolbarShow
}: {
  height: number
  params: URLSearchParams
  toolbarShow: boolean
  labels: Array<string>
}) {
  const { Stats } = useFetcherV2()
  const { t } = useTranslation()

  const { data = { monthly_sales: [] }, isFetching: isLoading } = useQueryFetcher({
    refetchOnWindowFocus: false,
    queryKey: ['stats', 'pilotage-domiciliation', params.toString()],
    enabled: !!params.get('center'),
    queryFn: async () => {
      const searchParams = new URLSearchParams(params)
      const data = await Stats.statsContractsDomDuration(searchParams.toString())
      return data || { monthly_sales: [] }
    }
  })

  const generatedLabels = data.monthly_sales.map((monthData) =>
    dayjs(monthData.month).format('MMM YY')
  )

  const seriesData = useMemo(() => {
    return [
      {
        name: t('24_months'),
        data: data.monthly_sales.map((month) => month.engagement_durations['24_months'] || 0),
        type: 'bar',
        color: chartEngagementMonthsPalette.royalBlue + '90'
      },
      {
        name: t('12_months'),
        data: data.monthly_sales.map((month) => month.engagement_durations['12_months'] || 0),
        type: 'bar',
        color: chartEngagementMonthsPalette.azureBlue + '90'
      },
      {
        name: t('6_months'),
        data: data.monthly_sales.map((month) => month.engagement_durations['6_months'] || 0),
        type: 'bar',
        color: chartEngagementMonthsPalette.glacierBlue + '90'
      },
      {
        name: t('3_months'),
        data: data.monthly_sales.map((month) => month.engagement_durations['3_months'] || 0),
        type: 'bar',
        color: chartEngagementMonthsPalette.paleBlue + '90'
      },
      {
        name: t('contractCount') + ' ' + t('span_signed_contracts').toLowerCase(),
        data: data.monthly_sales.map((month) => month.contracts_sold || 0),
        type: 'line',
        color: '#000000'
      }
    ]
  }, [data.monthly_sales, t])

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: toolbarShow,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        stacked: true
      },
      stroke: {
        width: [0, 0, 0, 0, 2],
        colors: ['#000000'],
        curve: 'smooth'
      },
      xaxis: {
        categories: generatedLabels,
        labels: {
          rotate: -45,
          rotateAlways: true,
          style: {
            colors: '#333',
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: (val: number) => val.toFixed(0)
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          distributed: false
        }
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      legend: {
        position: 'top',
        markers: {
          radius: 12,
          shape: 'circle'
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      }
    }
  }, [generatedLabels, toolbarShow])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={seriesData} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
