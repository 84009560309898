import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const presenceLogSchema = z.object({
  presenceId: z.number(),
  centerName: z.string(),
  individualName: z.string(),
  individualId: z.number(),
  presenceType: z.string(),
  ids: z.array(z.number()),
  presenceDate: z.string(),
  h0: z.string(),
  h1: z.string(),
  h2: z.string(),
  h3: z.string(),
  h4: z.string(),
  h5: z.string(),
  h6: z.string(),
  h7: z.string(),
  h8: z.string(),
  h9: z.string(),
  h10: z.string(),
  h11: z.string(),
  h12: z.string(),
  h13: z.string(),
  h14: z.string(),
  h15: z.string(),
  h16: z.string(),
  h17: z.string(),
  h18: z.string(),
  h19: z.string(),
  h20: z.string(),
  h21: z.string(),
  h22: z.string(),
  h23: z.string()
})

export type PresenceLog = z.infer<typeof presenceLogSchema>
export const PresenceLogsListSchema = itemListSchema(presenceLogSchema)
export type PresenceLogsList = z.infer<typeof PresenceLogsListSchema>

const presenceScheduleSchema = z.object({
  individualId: z.number(),
  individualName: z.string(),
  individualReference: z.string(),
  total: z.number(),
  presencesInfo: z.record(z.array(z.number()))
})
export type PresenceSchedule = z.infer<typeof presenceScheduleSchema>

export const presenceScheduleListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(presenceScheduleSchema)
})
export type PresenceScheduleList = z.infer<typeof presenceScheduleListSchema>
