import React, { useEffect, useMemo, useState } from 'react'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import dayjs from 'dayjs'
import { ApexOptions } from 'apexcharts'
import { Box, Card, CardContent, useTheme } from '@mui/material'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { ThemedChart } from 'app/components/charts/timeline.component'
import i18n from 'i18next'
import getLocales from 'app/utils/apexlocales.util'
import { useTranslation } from 'react-i18next'
import { reversedChartPalette } from 'app/helpers/color.helper'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

interface NomadicData {
  nomadic100: Record<string, number>;
  nomadic105: Record<string, number>;
  nomadic110: Record<string, number>;
  nomadic200: Record<string, number>;
}

export const NomadOccupationStats = ({ height, params }: { height: number, params: URLSearchParams }) => {
  const { Stats } = useFetcherV2()
  const { t } = useTranslation()
  const [locales, setLocales] = useState<any>([])

  const { data = [], isFetching: isLoading } = useQueryFetcher<NomadicData[]>({
    queryKey: ['stats', 'number-users-nomad-occupation', 'clients', params.toString()],
    queryFn: async () => {
      const searchParams = new URLSearchParams(params)
      return [await Stats.numberUsersNomadicOccupation(searchParams.toString())]
    }
  })

  const theme = useTheme()

  useEffect(() => {
    getLocales().then(setLocales)
  }, [])

  const { labels, series } = useMemo(() => {
    if (!data.length) return { labels: [], series: [] }
    const dates = Object.keys(data[0].nomadic100)
    const labels = dates.map(date => dayjs(date).toDate().getTime())
    const series = data.flatMap((nomadicData: NomadicData) =>
      Object.entries(nomadicData).map(([key, values]: [string, Record<string, number>]) => ({
        name: t(key),
        data: dates.map(date => (values[date] === 0 ? null : values[date])),
        type: 'bar' as const
      }))
    )
    return { labels, series }
  }, [data, t])

  const options: ApexOptions = useMemo(() => ({
    chart: {
      stacked: true,
      locales: [locales],
      defaultLocale: i18n.language,
      toolbar: { show: false },
      animations: { enabled: true, easing: 'easeout', speed: 800, dynamicAnimation: { enabled: true, speed: 350 } }
    },
    stroke: { width: 3, curve: 'smooth' },
    fill: { opacity: 1 },
    responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }],
    tooltip: {
      shared: true,
      intersect: false,
      x: { formatter: val => dayjs(val).format('MMMM YYYY') },
      y: {
        title: { formatter: val => `${val} :` },
        formatter: y => (y === null ? '0' : y?.toString() ?? y)
      }
    },
    xaxis: {
      type: 'category',
      categories: labels,
      tickAmount: labels.length,
      labels: { rotate: -45, rotateAlways: true, formatter: value => dayjs(value).format('MMM YY') },
      tooltip: { enabled: false }
    },
    yaxis: { labels: { formatter: val => Math.round(val).toString() } },
    legend: { position: 'top', markers: { shape: 'circle' }, showForNullSeries: false },
    dataLabels: { enabled: false },
    plotOptions: { bar: { horizontal: false } },
    colors: reversedChartPalette,
    theme: { mode: theme.palette.mode }
  }), [labels, locales, theme.palette.mode])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={series} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}