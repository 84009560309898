import { z } from 'zod'
const companyListSchema = z.object({
  id: z.number(),
  reference: z.string(),
  company_name: z.string(),
  siret: z.string(),
  par: z.string(),
  vat: z.string()
})

const companiesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(companyListSchema)
})

export type CompanyListSchema = z.infer<typeof companyListSchema>
export type CompaniesListSchema = z.infer<typeof companiesListSchema>

const companySchema = z.object({
  id: z.number(),
  reference: z.string(),
  companyName: z.string(),
  siret: z.string(),
  par: z.string(),
  vat: z.string(),
  address: z.string(),
  zipCode: z.string(),
  city: z.string(),
  country: z.string(),
  capital: z.number(),
  rcs: z.string(),
  rcsCity: z.string(),
  currencyLabel: z.number()
})

export type Company = z.infer<typeof companySchema>

export const postCompanySchema = z.object({
  reference: z.string(),
  companyName: z.string(),
  siret: z.string().min(14),
  // par: z.string(),
  vat: z.string(),
  address: z.string(),
  zipCode: z.string(),
  city: z.string(),
  country: z.string(),
  currency: z.number({ coerce: true }),
  centers: z.array(z.number({ coerce: true })),
  // Configuration
  maxAmountDirectDebit: z.number({ coerce: true }).optional(),
  gcAccessToken: z.string().optional(),
  stripePublicKey: z.string().optional(),
  stripeSecretKey: z.string().optional()
})

export type PostCompany = z.infer<typeof postCompanySchema>

const companyCenters = z.array(
  z.object({
    id: z.number(),
    reference: z.string(),
    centerName: z.string(),
    city: z.string()
  })
)

export type CompanyCenters = z.infer<typeof companyCenters>

const companyConfiguration = z.object({
  id: z.number(),
  gcAccessToken: z.string(),
  stripeSecretKey: z.string(),
  stripePublicKey: z.string(),
  maxAmountDirectDebit: z.number()
})

export type CompanyConfiguration = z.infer<typeof companyConfiguration>

const companyBankAccount = z.object({
  id: z.number(),
  paymentMode: z.string(),
  iban: z.string(),
  bic: z.string(),
  journalCode: z.string(),
  accountingNumber: z.string(),
  partnerBankAccountId: z.string(),
  companyId: z.number()
})

export type CompanyBankAccount = z.infer<typeof companyBankAccount>

const companyBankAccounts = z.array(companyBankAccount)

export type CompanyBankAccounts = z.infer<typeof companyBankAccounts>
export const companyBankAccountBodySchema = z.object({
  companyId: z.number().gt(0),
  paymentModeId: z.number().gt(0),
  iban: z.string().min(1),
  bic: z.string().min(1),
  journalCode: z.string().min(1),
  accountingNumber: z.string().min(1),
  partnerBankAccountId: z.string().optional()
})

export type CompanyBankAccountBody = z.infer<typeof companyBankAccountBodySchema>
