import { type Ctx, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import { type ConsumptionList, ConsumptionsList, ConsumptionsListParams } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const consumptionsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<ConsumptionList>({
      url: `${BASE_URL}${API.CONSUMPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  listForClient: async (params: ConsumptionsListParams) =>
    fetcher<ConsumptionsList>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_CONSUMPTIONS, { id: params.clientId })}?center=${
        params.center
      }&serviceTypeReference=${params.serviceTypeReference}`,
      method: 'GET',
      token: token
    }),
  getClientContractsConsumptions: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_CONTRACT_CONSUMPTIONS, { id })}`,
      method: 'GET',
      token: token
    })
})
