import { Button, Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { EnterpriseInformation, FormItem } from 'api/models'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { CreateSsidForm, createSsidFormSchema } from 'api/models/forms/enterprises'

export const AddSsid = function ({
  enterprise,
  onSave
}: {
  enterprise: EnterpriseInformation
  onSave: () => void
}) {
  const { t } = useTranslation()
  const { createEnterpriseSsid, getFormItemsWithFilters } = useFetcher()
  const methods = useForm<CreateSsidForm>({
    mode: 'onChange',
    resolver: zodResolver(createSsidFormSchema)
  })

  const [centers, setCenters] = useState<FormItem>({} as FormItem)
  const { handleMutation } = useFeedback()
  const fetchCenters = useCallback(async () => {
    const options = await getFormItemsWithFilters.mutateAsync({
      filters: ['ssid_centers'],
      references_filters: {
        ssid_centers: { enterprise: enterprise.id }
      }
    })

    setCenters(options.ssid_centers as FormItem)
    if (
      options.ssid_centers &&
      options.ssid_centers.values &&
      options.ssid_centers.values.length > 0
    ) {
      methods.setValue('center', String(options.ssid_centers.values[0].id))
    }
  }, [enterprise, setCenters, methods])

  useEffect(() => {
    fetchCenters().then()
  }, [])

  const handleSubmit = useCallback(async (data: CreateSsidForm) => {
    await handleMutation({
      confirm: {
        content: t('confirm_add_ssid')
      },
      data: { id: enterprise.id, data: data },
      mutation: createEnterpriseSsid,
      onSuccess: () => onSave()
    })
  }, [])

  return (
    <Grid container columns={12} paddingBottom={4} paddingX={4} gap={4}>
      <Grid item xs={4}>
        <ControlledTextField control={methods.control} name={'name'} label={t('name')} />
      </Grid>
      <Grid item xs={4}>
        <ControlledSelectField
          label={t('center')}
          control={methods.control}
          name={'center'}
          formItem={centers}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant={'contained'}
          disabled={!methods.formState.isValid || methods.formState.isSubmitting}
          onClick={methods.handleSubmit(handleSubmit)}
        >
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  )
}
