import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { CentersSelect, CentersSelectInputProps } from 'app/components/filters/centers-select'

type IControlledTextFieldProps = {
  label: string
  name: string
  control: Control<any>
  required?: boolean
  disabled?: boolean
} & Omit<CentersSelectInputProps, 'onChange' | 'disabled'>

export function ControlledSelectCentersField({
  name,
  label,
  control,
  required,
  ...otherProps
}: IControlledTextFieldProps) {
  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <CentersSelect
              {...otherProps}
              required={required}
              disableClearable={required}
              slug={label}
              onChange={(newValue: any) => {
                if (otherProps.multiple)
                  onChange((newValue ?? []).map((center: any) => Number(center.id)))
                else onChange(newValue ? Number(newValue.id) : 0)
              }}
              initialValue={value}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
