import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import {
  Chip,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { darken, styled } from '@mui/system'
import dayjs from 'dayjs'
import {
  CaByTypesByCenters,
  CaByTypesByCentersCenterSchema,
  CaByTypesByCentersRecurrenceSchema,
  CaByTypesByCentersServiceTypeSchema
} from 'api/models/stats'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { formatCurrency } from 'app/utils/format'
import { Link } from 'app/components/link.component'

const LoadingContainer = styled('div')({
  display: 'grid',
  placeItems: 'center',
  height: '100%'
})

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: 500,
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,

  table: {
    minWidth: 650,
    tableLayout: 'fixed',
    borderCollapse: 'separate'
  }
}))

export const TableCaByTypesByCenterComponent = () => {
  const { searchParams, useGetStatsCaByTypesByCenters } = useFetcher()
  const { data, isLoading } = useGetStatsCaByTypesByCenters()
  const { t } = useTranslation()

  const months = useMemo(() => {
    const begin = searchParams.get('date_begin')
    const end = searchParams.get('date_end')
    if (!begin || !end) return []

    const beginDate = dayjs(begin).startOf('month')
    const endDate = dayjs(end).endOf('month')

    if (beginDate.isAfter(endDate)) return []

    const monthsList: Array<string> = []
    let currentDate = beginDate.clone()
    while (currentDate.isBefore(endDate)) {
      monthsList.push(currentDate.format('MM.YYYY'))
      currentDate = currentDate.add(1, 'month')
    }
    return monthsList
  }, [searchParams])

  const handleHorizontalScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    e.currentTarget.dataset.scrolled = String(e.currentTarget.scrollLeft > 0)
  }, [])

  const columns: TableColumn[] = useMemo(
    () => [
      {
        label: t('center'),
        key: 'center',
        width: 150,
        sticky: true
      },
      { label: t('service_type'), key: 'serviceType', width: 220, sticky: true },
      { label: t('recurrence'), key: 'isRecurrent', width: 110, sticky: true, shadow: true },
      ...months.map((month) => ({ label: month, key: month, width: 100 }))
    ],
    [t, months]
  )

  return (
    <StyledTableContainer onScroll={handleHorizontalScroll}>
      <Table size="small">
        <TableHead>
          <HeaderRow columns={columns} />
        </TableHead>
        <TableBody>{!isLoading && <GroupedRows data={data} months={months} />}</TableBody>
      </Table>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
    </StyledTableContainer>
  )
}

const StyledTableCellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== '$sticky' && prop !== '$shadow'
})<{ $sticky?: number; $shadow?: boolean }>(({ theme, $sticky, $shadow }) => ({
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  background: darken(theme.palette.background.default, 0.05),
  top: 0,
  zIndex: $sticky !== undefined ? 10 : 1,
  borderBottom: `1px solid ${theme.palette.divider}!important`,
  left: $sticky ?? 'auto',
  position: 'sticky',
  boxSizing: 'border-box',
  '[data-scrolled="true"] &': {
    boxShadow: $shadow ? '5px 0px 10px rgba(0, 0, 0, 0.1)' : 'none'
  }
}))

type TableColumn = {
  label: string
  key: string
  width: number
  sticky?: boolean
  shadow?: boolean
}

const HeaderRow = ({ columns }: { columns: TableColumn[] }) => {
  const getStickyOffset = (col: TableColumn) => {
    if (!col.sticky) return undefined
    const colIndex = columns.findIndex((c) => c.key === col.key)
    if (colIndex === 0) return 0
    return columns
      .slice(0, colIndex)
      .filter((c) => c.sticky)
      .reduce((acc, col) => acc + col.width, 0)
  }

  return (
    <TableRow>
      {columns.map((col) => (
        <StyledTableCellHeader
          width={col.width}
          $sticky={getStickyOffset(col)}
          $shadow={col.shadow}
          key={col.key}
          data-cy-sticky={col.sticky}
          data-cy-col-key={col.key}
        >
          {col.label}
        </StyledTableCellHeader>
      ))}
    </TableRow>
  )
}

const GroupedRows = ({
  data,
  months
}: {
  data: CaByTypesByCenters | undefined
  months: string[]
}) => {
  const [groupsCollapsed, setGroupsCollapsed] = useState<string[]>([])
  const toggleGroupCollapse = (group: string) => {
    if (groupsCollapsed.includes(group))
      setGroupsCollapsed(groupsCollapsed.filter((g) => g !== group))
    else setGroupsCollapsed([...groupsCollapsed, group])
  }
  const isGroupCollapsed = useCallback(
    (group: string) => groupsCollapsed.includes(group),
    [groupsCollapsed]
  )

  useEffect(() => {
    if (data) {
      const collapsedGroups = data.reduce((acc, center) => {
        return [
          ...acc,
          ...center.children
            .filter((serviceType) => !serviceType.isTotalCenter)
            .filter((serviceType) => serviceType.children.length > 2)
            .map((serviceType) => serviceType.serviceTypeGroupRef)
        ]
      }, [] as string[])
      setGroupsCollapsed(collapsedGroups)
    }
  }, [data])

  return (data ?? []).map((center) => (
    <React.Fragment key={center.centerRef}>
      {center.children
        .filter((serviceType) => !isGroupCollapsed(center.centerRef) || serviceType.isTotalCenter)
        .map((serviceType, indexServiceType) => (
          <React.Fragment key={serviceType.serviceTypeGroupRef ?? ''}>
            {serviceType.children
              .filter(
                (recurrence) =>
                  !isGroupCollapsed(serviceType.serviceTypeGroupRef) ||
                  recurrence.isTotalServiceType
              )
              .filter((recurrence) => {
                if (!recurrence.isTotalServiceType) return true
                return serviceType.children.length > 2
              })
              .map((recurrence, indexRecurrence) => (
                <Row
                  key={String(recurrence.isRecurrent)}
                  center={center}
                  serviceType={serviceType}
                  recurrence={recurrence}
                  indexServiceType={indexServiceType}
                  indexRecurrence={indexRecurrence}
                  isGroupCollapsed={isGroupCollapsed}
                  toggleGroupCollapse={toggleGroupCollapse}
                  months={months}
                />
              ))}
          </React.Fragment>
        ))}
    </React.Fragment>
  ))
}

const CollapseButton = styled(Chip)(() => ({
  width: 18,
  height: 18,
  cursor: 'pointer',
  flexShrink: 0,
  borderRadius: 5,

  span: {
    display: 'grid',
    placeItems: 'center',
    padding: 0,

    svg: {
      fontSize: 16
    }
  }
}))

const StyledTableRow = styled(TableRow, { shouldForwardProp: (prop) => prop !== '$highlighted' })<{
  $highlighted?: boolean
}>(({ theme, $highlighted }) => ({
  td: {
    fontWeight: $highlighted ? '600' : 'normal',
    backgroundColor: $highlighted
      ? darken(theme.palette.background.default, 0.05)
      : theme.palette.background.paper
  }
}))

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== '$sticky' && prop !== '$shadow'
})<{ $sticky?: number; $shadow?: boolean }>(({ theme, $sticky, $shadow }) => ({
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  verticalAlign: 'baseline',
  position: $sticky !== undefined ? 'sticky' : 'inherit',
  left: $sticky ?? 'auto',

  '[data-scrolled="true"] &': {
    boxShadow: $shadow ? '5px 0px 10px rgba(0, 0, 0, 0.1)' : 'none'
  }
}))

interface IRowProps {
  center: CaByTypesByCentersCenterSchema
  serviceType: CaByTypesByCentersServiceTypeSchema
  recurrence: CaByTypesByCentersRecurrenceSchema
  indexServiceType: number
  indexRecurrence: number
  isGroupCollapsed: (group: string) => boolean
  toggleGroupCollapse: (group: string) => void
  months: string[]
}

const Row = memo(
  ({
    center,
    serviceType,
    recurrence,
    indexServiceType,
    indexRecurrence,
    isGroupCollapsed,
    toggleGroupCollapse,
    months
  }: IRowProps) => {
    const { t } = useTranslation()

    const isRowHighlighted = (
      centerRef: string,
      serviceTypeGroupRef: string,
      isTotal?: boolean
    ) => {
      if (!isTotal) return false
      if (isGroupCollapsed(centerRef)) return false
      if (isGroupCollapsed(serviceTypeGroupRef)) return false
      return true
    }

    const getCenterServiceTypeRowSpan = useCallback(
      (serviceType: CaByTypesByCentersServiceTypeSchema) => {
        if (isGroupCollapsed(serviceType.serviceTypeGroupRef)) return 1
        if (serviceType.children.length === 2) return 1
        return serviceType.children.length
      },
      [isGroupCollapsed]
    )

    const getCenterRowSpan = useCallback(
      (center: CaByTypesByCentersCenterSchema) => {
        if (isGroupCollapsed(center.centerRef)) return 1
        return center.children.reduce((acc: number, serviceType) => {
          return acc + getCenterServiceTypeRowSpan(serviceType)
        }, 0)
      },
      [isGroupCollapsed, getCenterServiceTypeRowSpan]
    )

    const centerCell = useMemo(() => {
      return (
        <StyledTableCell
          rowSpan={getCenterRowSpan(center)}
          $sticky={0}
          data-cy="center-cell"
          data-cy-center-id={center.centerId}
        >
          <Stack flexDirection="row" gap={4} justifyContent="space-between">
            <Link to={`/centers/${center.centerId}`}>{center.centerName}</Link>
            <CollapseButton
              label={isGroupCollapsed(center.centerRef) ? <AddIcon /> : <RemoveIcon />}
              onClick={() => toggleGroupCollapse(center.centerRef)}
            />
          </Stack>
        </StyledTableCell>
      )
    }, [getCenterRowSpan, center, isGroupCollapsed, toggleGroupCollapse])

    const serviceTypeCell = useMemo(() => {
      return (
        <StyledTableCell rowSpan={getCenterServiceTypeRowSpan(serviceType)} $sticky={150}>
          <Stack flexDirection="row" gap={4} justifyContent="space-between">
            {serviceType.serviceTypeLabel}
            {serviceType.isTotalCenter && !isGroupCollapsed(center.centerRef) && t('total')}
            {!serviceType.isTotalCenter && serviceType.children.length > 2 && (
              <CollapseButton
                label={
                  isGroupCollapsed(serviceType.serviceTypeGroupRef) ? <AddIcon /> : <RemoveIcon />
                }
                onClick={() => toggleGroupCollapse(serviceType.serviceTypeGroupRef)}
              />
            )}
          </Stack>
        </StyledTableCell>
      )
    }, [getCenterServiceTypeRowSpan, serviceType, isGroupCollapsed, center, toggleGroupCollapse, t])

    const recurrenceCell = useMemo(() => {
      return (
        <StyledTableCell $sticky={370} $shadow>
          {recurrence.isTotalServiceType &&
            !serviceType.isTotalCenter &&
            !isGroupCollapsed(serviceType.serviceTypeGroupRef) &&
            t('total')}
          {recurrence.isRecurrent === true && t('recurrent')}
          {recurrence.isRecurrent === false && t('one_time')}
          {isGroupCollapsed(serviceType.serviceTypeGroupRef) && t('all_recurrences')}
        </StyledTableCell>
      )
    }, [recurrence, serviceType, isGroupCollapsed, t])

    const monthCells = useMemo(() => {
      return months.map((month) => {
        const value = recurrence.values.find((v) => v.month === month)?.value ?? 0
        return <StyledTableCell key={month}>{formatCurrency(value)}</StyledTableCell>
      })
    }, [months, recurrence])

    return (
      <StyledTableRow
        key={indexRecurrence}
        $highlighted={isRowHighlighted(
          center.centerRef,
          serviceType.serviceTypeGroupRef,
          serviceType.isTotalCenter || recurrence.isTotalServiceType
        )}
      >
        {indexServiceType === 0 && indexRecurrence === 0 && centerCell}
        {indexRecurrence === 0 && serviceTypeCell}
        {recurrenceCell}
        {monthCells}
      </StyledTableRow>
    )
  }
)
