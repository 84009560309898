import React, { useEffect } from 'react'
import { Box, Card, Container, Grid, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import dayjs from 'dayjs'
import { MonthPicker } from 'app/components/filters/month-picker'
import { formatDate } from 'app/utils/format'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { FloorSelectInput } from 'app/components/filters/floor_select_input'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

export const FloorPlansManagementView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams } = useFetcher()
  const { orderBy, handleSort, isLast, total, handleFilter } = useList()
  const { FloorPlansManagement } = useFetcherV2()

  useEffect(() => {
    if (!searchParams.get('date')) {
      const now = dayjs()
      searchParams.set('date', now.endOf('month').format('YYYY-MM-DD'))
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('offset')
      return searchParams
    })
  }, [])

  const {
    isFetching: isLoading,
    items,
    refreshList
  } = useQueryFetcherList({
    queryKey: ['floorPlansManagement', 'list'],
    queryFn: () => FloorPlansManagement.list(searchParams.toString()),
    enabled: searchParams.has('date')
  })

  const handleCenterChange = () => {
    setSearchParams((searchParams) => {
      searchParams.delete('floor')
      return searchParams
    })
  }

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('floor_plan_management')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <MonthPicker
            slug={'date'}
            label={t('date')}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            views={['year', 'month', 'day']}
            format={'YYYY-MM-DD'}
          />
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            onChange={handleCenterChange}
          />
          <FloorSelectInput searchParams={searchParams} setSearchParams={setSearchParams} />
        </FiltersContainer>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {items.length} / {total}
        </Typography>
      </Grid>
      <Card variant="outlined">
        <List
          items={items}
          columns={[
            { label: t('center'), slug: 'centerName' },
            { label: t('floor'), slug: 'floor' },
            { label: t('index'), slug: 'index' },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'end', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(null, property)}
          isLoading={isLoading}
        />
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Card>
    </Container>
  )
}
