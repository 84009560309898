import { z } from 'zod'

const clientRequestSchema = z.object({
  id: z.number(),
  status: z.number(),
  priority: z.number(),
  center: z.number(),
  individual: z.number(),
  enterprise: z.number(),
  staff: z.number(),
  staffInitial: z.string(),
  category: z.string(),
  subcategory: z.string(),
  begin: z.string(),
  end: z.string(),
  statusLabel: z.string()
})

export type ClientRequest = z.infer<typeof clientRequestSchema>

const clientRequestListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(clientRequestSchema)
})

export type ClientRequestList = z.infer<typeof clientRequestListSchema>

const clientRequestSumSchema = z.object({
  total: z.string(),
  created: z.string().nullable(),
  inprogress: z.string().nullable(),
  treated: z.string().nullable(),
  cancelled: z.string().nullable()
})

export type ClientRequestSum = z.infer<typeof clientRequestSumSchema>

const clientRequestInformationSchema = z.object({
  id: z.number(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  individualId: z.number(),
  individualName: z.string(),
  centerName: z.string(),
  categoryId: z.number(),
  categoryLabel: z.string(),
  subcategoryId: z.number(),
  subcategoryLabel: z.string(),
  createdAt: z.string(),
  end: z.string(),
  statusId: z.number(),
  statusLabel: z.string(),
  staffId: z.number(),
  staffName: z.string(),
  comment: z.string(),
  internalComment: z.string()
})

export type ClientRequestInformation = z.infer<typeof clientRequestInformationSchema>
