import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper as MuiPaper } from '@mui/material'
import type { EnterpriseInformation } from 'api/models'
import { formatDate, formatOpportunityStatus } from 'app/utils/format'
import React, { useEffect } from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

interface IEnterpriseOpportunities {
  enterprise: EnterpriseInformation
}

export function EnterpriseOpportunitiesComponent({ enterprise }: IEnterpriseOpportunities) {
  const { t } = useTranslation()
  const { orderBy, handleSort, handleFilter } = useList()
  const { Enterprises } = useFetcherV2()
  const { items, isLoading, refreshList } = useQueryFetcherList({
    queryKey: ['enterprises', 'opportunities', enterprise.id],
    queryFn: () => Enterprises.listOpportunities(enterprise.id)
  })

  useEffect(() => {
    refreshList().then()
  }, [enterprise])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('opportunities')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: '',
                slug: 'status',
                text: 'statusLabel',
                valueFormatter: formatOpportunityStatus
              },
              {
                label: t('reference'),
                slug: 'reference',
                link: { base: '/opportunities', slug: 'id' }
              },
              {
                label: t('city'),
                slug: 'city'
              },
              {
                label: t('due_date'),
                slug: 'dueDate',
                valueFormatter: formatDate
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
