import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { Popover, Stack, Divider } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

export interface IProps {
  slugStart: string
  slugEnd: string
  labelStart: string
  labelEnd: string
  onChange: () => void
  setSearchParams: (params: URLSearchParams) => void
  searchParams: URLSearchParams
  format?: string
  initialStartValue?: Dayjs
  initialEndValue?: Dayjs
}

export const RangePeriodPicker = ({
  slugStart,
  slugEnd,
  labelStart,
  labelEnd,
  onChange,
  setSearchParams,
  searchParams,
  format = 'YYYY-MM-DD',
  initialStartValue,
  initialEndValue
}: IProps): React.JSX.Element => {
  const defaultStartValue = initialStartValue ?? dayjs().subtract(11, 'month').startOf('month')
  const defaultEndValue = initialEndValue ?? dayjs().endOf('month')

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    const startParam = searchParams.get(slugStart)
    const endParam = searchParams.get(slugEnd)

    searchParams.set(slugStart, startParam ?? defaultStartValue.format(format))
    searchParams.set(slugEnd, endParam ?? defaultEndValue.format(format))

    setStartDate(startParam ? dayjs(startParam) : defaultStartValue)
    setEndDate(endParam ? dayjs(endParam) : defaultEndValue)
  }, [slugStart, slugEnd, searchParams, defaultStartValue, defaultEndValue, format])

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDateChange = (
    val: Dayjs | null,
    slug: string,
    setDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
  ) => {
    if (val) {
      searchParams.set(slug, val.format(format ?? 'YYYY-MM'))
    } else {
      searchParams.delete(slug)
    }
    setDate(val)
    setSearchParams(searchParams)
    onChange()
  }

  return (
    <>
      <div onClick={handleClick} style={{ width: 200 }}>
        <SingleInputDateRangeField
          label={`${labelStart} - ${labelEnd}`}
          format="MM/YYYY"
          fullWidth
          size={'small'}
          value={[startDate, endDate]}
          onChange={(date) => {
            setStartDate(date[0] ?? null)
            setEndDate(date[1] ?? null)
            onChange()
          }}
        />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack flexDirection="row">
          <DateCalendar
            minDate={dayjs().subtract(2, 'year').startOf('year')}
            maxDate={dayjs().add(1, 'year').endOf('year')}
            views={['month', 'year']}
            value={startDate}
            onChange={(val) => handleDateChange(val, slugStart, setStartDate)}
          />
          <Divider orientation="vertical" flexItem />
          <DateCalendar
            minDate={dayjs().subtract(2, 'year').startOf('year')}
            maxDate={dayjs().add(1, 'year').endOf('year')}
            views={['month', 'year']}
            value={endDate}
            onChange={(val) => handleDateChange(val, slugEnd, setEndDate)}
          />
        </Stack>
      </Popover>
    </>
  )
}
