import { type Ctx, fetcher, formatParams, uploader } from 'api'
import { API, BASE_URL } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  AccessGroup,
  AccessRoom,
  AddClusterProps,
  CenterAddProps,
  CenterBlockedPrices,
  CenterBp,
  CenterClustersList,
  CenterDetails,
  CentersList,
  CreateCenterPriceInputs,
  EditClusterProps,
  IdReturn,
  Visors
} from 'api/models'
import { CenterPricesList } from 'api/models/centers'
import { IdLabelItem } from 'api/models/commons'

export const centersRouter = ({ token }: Ctx) => ({
  getOne: async (id: number) =>
    fetcher<CenterDetails>({
      url: `${BASE_URL}${urlHelper(API.CENTER, { id })}`,
      method: 'GET',
      token: token
    }),
  listAccessGroups: async (id: number) =>
    fetcher<AccessGroup[]>({
      url: `${BASE_URL}${urlHelper(API.CENTER_VISOR_GROUPS, { id })}`,
      method: 'GET',
      token: token
    }),
  listAccessRooms: async (id: number, type: string | null, status: string | null) => {
    let params = []
    if (type) {
      params.push(`type=${type}`)
    }
    if (status) {
      params.push(`status=${status}`)
    }
    return fetcher<AccessRoom[]>({
      url: `${BASE_URL}${urlHelper(API.VISOR_ROOMS, { id })}${formatParams(params.join('&'))}`,
      method: 'GET',
      token: token
    })
  },
  listVisors: async () =>
    fetcher<Visors>({
      url: `${BASE_URL}${API.CENTERS_VISORS}`,
      method: 'GET',
      token: token
    }),
  listPrices: async (params: string) =>
    fetcher<CenterPricesList>({
      url: `${BASE_URL}${API.CENTER_PRICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  createPrice: async (data: CreateCenterPriceInputs) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.CENTER_PRICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  listBlockedServicePrices: async (centerId: number) =>
    fetcher<CenterBlockedPrices>({
      url: `${BASE_URL}${urlHelper(API.CENTER_PRICES_BLOCKED_PRICES, { id: centerId })}`,
      method: 'GET',
      token: token
    }),
  list: async () =>
    fetcher<CentersList>({
      url: `${BASE_URL}${API.CENTERS}`,
      method: 'GET',
      token: token
    }),
  listClusters: async (params: string) =>
    fetcher<CenterClustersList>({
      url: `${BASE_URL}${API.CLUSTERS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async (id: number, data: CenterDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CENTER, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateCluster: async (id: number, data: EditClusterProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CLUSTER, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getAvailableCentersForCluster: async (id: number) =>
    fetcher<IdLabelItem[]>({
      url: `${BASE_URL}${urlHelper(API.CLUSTER, { id })}/available_centers`,
      method: 'GET',
      token: token
    }),
  createCluster: async (data: AddClusterProps) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.CLUSTERS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  uploadCSV: async (file: any) => {
    const formData = new FormData()
    formData.append('body', file)
    return uploader<void>({
      url: `${BASE_URL}${API.BUSINESS_PLAN}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  businessPlan: async () => {
    return fetcher<CenterBp[]>({
      url: `${BASE_URL}${API.BUSINESS_PLAN}`,
      method: 'GET',
      token: token
    })
  },
  createCenter: async (data: CenterAddProps) =>
    fetcher<void>({
      url: `${BASE_URL}${API.CENTERS}`,
      method: 'POST',
      token: token,
      body: data
    })
})
