import { Alert, FormControlLabel, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { Box, Container, styled } from '@mui/system'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { PresenceSchedule } from 'api/models'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { ListPagination } from 'app/components/lists/list-pagination'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useApp } from 'app/providers/app.provider'
import { Switch } from 'app/components/switch/switch'

export const UsersPresencesView = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <>
        <Typography variant="h2" gutterBottom>
          {t('presence_users')}
        </Typography>
        <Filters />
        <ListPresences />
      </>
    </Container>
  )
}

const Filters = () => {
  const { searchParams, setSearchParams } = useFetcher()
  const { handleFilter } = useList()
  const { t } = useTranslation()
  const { getRight } = useApp()

  return (
    <FiltersContainer
      setSearchParams={setSearchParams}
      searchParams={searchParams}
      sx={{ marginBottom: 0 }}
    >
      <SearchCenterInput
        slug="center"
        onChange={() => handleFilter(null, true)}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        byPassAllCenters={getRight('select_center', 'isDisplay')}
        withDefaultValue
        disableClearable
      />
      <SearchClientInput
        slug="enterprise"
        defaultIsEnterprise={true}
        defaultIsIndividual={false}
        onChange={() => handleFilter(null, true)}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <SearchClientInput
        slug="client"
        defaultIsIndividual={true}
        defaultIsEnterprise={false}
        onChange={() => handleFilter(null, true)}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <RangePicker
        label={t('date')}
        slugs={{ begin: 'dateBegin', end: 'dateEnd' }}
        slug="date"
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        valueLabel={dates.TODAY}
        isNoDate={false}
      />
    </FiltersContainer>
  )
}

const ListPresences = () => {
  const { t } = useTranslation()
  const { orderBy, handleSort } = useList()
  const navigate = useNavigate()

  const { searchParams, useGetPresenceSchedules } = useFetcher()
  const searchDependencies = useMemo(
    () =>
      Array.from(searchParams.entries())
        .filter((entry) => entry[0] !== 'offset')
        .map((entry) => entry.join('=')),
    [searchParams]
  )
  const isCenterSelected = useMemo(() => searchParams.has('center'), [searchParams])

  const {
    data: presenceSchedules,
    isPending,
    isError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useGetPresenceSchedules({
    enabled: isCenterSelected,
    additionalQueryKeys: searchDependencies
  })

  const fullListPresenceSchedules = useMemo(() => {
    return presenceSchedules?.pages.map((page) => page.items).flat()
  }, [presenceSchedules])

  const total = useMemo(() => {
    if (!presenceSchedules) return 0
    const index = presenceSchedules.pageParams.length - 1
    const staffsRes = presenceSchedules.pages[index]
    return staffsRes.total
  }, [presenceSchedules])

  const [anonymizedNames, setAnonymizedNames] = useState(true)
  const handleToggleAnonymizedNames = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked
      setAnonymizedNames(value)
    },
    [setAnonymizedNames]
  )

  const columns = useMemo<ListColumnsProps<PresenceSchedule>>(() => {
    const dateColumns = Object.keys(fullListPresenceSchedules?.at(0)?.presencesInfo ?? {})

    const modeDay = dateColumns.length === 1
    let formatedItems: ListColumnsProps<PresenceSchedule>

    if (modeDay) {
      const day = dateColumns[0]
      const hours = Array.from({ length: 24 }, (_, i) => i)
      formatedItems = hours.map((hour) => {
        return {
          label: `${hour}h`,
          slug: 'individualId',
          unsorted: true,
          condition: (presenceSchedule) => {
            const data = presenceSchedule.presencesInfo[day]
            if (data.includes(hour)) return <PresenceIndicator $primary />
          }
        }
      })
    } else {
      formatedItems = dateColumns.map((date) => {
        return {
          label: dayjs(date).format('DD MMM'),
          slug: 'individualId',
          unsorted: true,
          condition: (presenceSchedule) => {
            const data = presenceSchedule.presencesInfo[date]
            return (
              <PresenceIndicator>
                {data.map((hour) => (
                  <MultiplePresenceIndicatorItem hour={hour} />
                ))}
              </PresenceIndicator>
            )
          }
        }
      })
    }

    return [
      {
        label: t('client'),
        slug: anonymizedNames ? 'individualReference' : 'individualName'
      },
      {
        label: t('total'),
        slug: 'total',
        condition: (presenceSchedule) => {
          return `${presenceSchedule.total} h`
        }
      },
      ...formatedItems
    ]
  }, [anonymizedNames, searchParams, t, presenceSchedules])

  const goToPresenceLog = useCallback(
    (presenceSchedule: PresenceSchedule) => {
      navigate(`/presences/logs?client=${presenceSchedule.individualId}`)
    },
    [navigate]
  )

  if (isError) {
    return (
      <Alert severity="error" sx={{ marginTop: 2 }}>
        {(error as Error).message}
      </Alert>
    )
  }

  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={anonymizedNames} onChange={handleToggleAnonymizedNames} />}
        label={t('anonymized_names')}
        sx={{ marginBottom: 2 }}
      />
      {isPending ? (
        <RectangularSkeleton width={100} height={18} sx={{ marginBottom: 2 }} />
      ) : (
        <Typography variant="body2" gutterBottom>
          {fullListPresenceSchedules?.length} / {total} {t('users')}
        </Typography>
      )}
      <Paper
        variant="outlined"
        sx={(theme) => ({
          overflow: 'hidden',
          backgroundColor: theme.palette.background.default
        })}
      >
        <List
          items={fullListPresenceSchedules ?? []}
          columns={columns}
          sort={orderBy}
          handleSort={(property) => handleSort(null, property)}
          isLoading={isPending}
          stickyHeader
          onClick={goToPresenceLog}
          sx={(theme) => ({
            'th span': {
              whiteSpace: 'nowrap'
            },
            'th:not(:nth-of-type(1), :nth-of-type(2)) span svg': {
              display: 'none'
            },
            maxHeight: 'calc(100vh - 261px)',
            '& td:not(:nth-of-type(1), :nth-of-type(2))': {
              padding: '0 1px'
            },
            '& td:not(:first-of-type)': {
              borderLeft: `1px solid ${theme.palette.divider}`
            },
            '& tr:last-of-type td': {
              borderBottom: 'none'
            }
          })}
          afterContent={
            hasNextPage && (
              <Box width="fit-content">
                <ListPagination
                  isLoading={isFetchingNextPage}
                  handleRedirect={() => fetchNextPage()}
                />
              </Box>
            )
          }
        />
      </Paper>
    </Box>
  )
}

const PresenceIndicator = styled(Box, { shouldForwardProp: (prop) => prop !== '$primary' })<{
  $primary?: boolean
}>`
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 28px;
  position: relative;
  ${({ $primary, theme }) => $primary && `background-color: ${theme.palette.primary.main};`}
`
const MultiplePresenceIndicatorItem = styled(Box)<{ hour: number }>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 100%;
  position: absolute;
  left: ${({ hour }) => `${(hour / 24) * 100}%`};
  width: ${(1 / 24) * 100}%;
`
