import { useTranslation } from 'react-i18next'
import { Grid, Paper as MuiPaper, Checkbox, Typography } from '@mui/material'
import type { IndividualDetails } from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import BaseModal from 'app/components/modals/base.modal'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

interface IIndividualEnterprises {
  individual: IndividualDetails
}

export function IndividualDevices({ individual }: IIndividualEnterprises) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { getIndividualDevices, individualDevicesUpdate } = useFetcher()
  const [devicesMap, setDevicesMap] = useState<Map<string, any>>(new Map())
  const [openActive, setOpenActive] = useState<boolean>(false)
  const [textActive, setTextActive] = useState<string>(t('device_active'))
  const [valueActive, setValueActive] = useState<number>(0)
  const navigate = useNavigate()
  const [isSend, setIsSend] = useState<boolean>(false)
  const { handleMutation } = useFeedback()

  const devicesData = useCallback(async () => {
    const data = await getIndividualDevices.mutateAsync(individual.id)
    setDevicesMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('device').toUpperCase(), value: 'deviceReference' },
        {
          label: t('active').toUpperCase(),
          component: (row: any) => (
            <Checkbox
              inputProps={{ id: row.deviceId }}
              size={'small'}
              checked={Boolean(row.deviceIsValid)}
              onChange={handlePreActive}
            />
          )
        }
      ])
      map.set('data', data)
      return map
    })
  }, [individual])

  const handlePreActive = useCallback((e: any) => {
    const id = Number(e.target.id)
    setValueActive(id)
    setOpenActive(true)
    setDevicesMap((prevState) => {
      prevState.get('data').forEach((item: any) => {
        if (item.deviceId === id) {
          item.deviceIsValid = e.target.checked
          if (item.deviceIsValid === false) {
            setTextActive(t('device_inactive'))
          } else {
            setTextActive(t('device_active'))
          }
        }
      })
      return prevState
    })
  }, [])

  useEffect(() => {
    if (!openActive && !isSend && devicesMap.size > 0) {
      setDevicesMap((prevState) => {
        const newData = prevState.get('data').map((item: any) => {
          if (item.deviceId === valueActive) {
            return { ...item, deviceIsValid: !item.deviceIsValid }
          }
          return item
        })
        const newState = new Map(prevState)
        newState.set('data', newData)
        return newState
      })
      setValueActive(0)
    }
  }, [isSend, openActive, valueActive])

  const handleActive = useCallback(
    async (e: any) => {
      e.preventDefault()
      await handleMutation({
        onStart: () => setIsSend(true),
        mutation: individualDevicesUpdate,
        data: {
          id: valueActive,
          data: {
            isValid: devicesMap.get('data').find((item: any) => item.deviceId === valueActive)
              .deviceIsValid
          }
        },
        onSuccess: () => navigate(0),
        onEnd: () => {
          setOpenActive(false)
          setValueActive(0)
        }
      })
    },
    [valueActive, devicesMap]
  )

  useEffect(() => {
    devicesData().finally(() => setIsLoading(false))
  }, [])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('devices')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <BaseModal
            open={openActive}
            setOpen={setOpenActive}
            title={'Activation'}
            handleUpdate={handleActive}
          >
            <Typography>{textActive}</Typography>
          </BaseModal>
          <ListSheetComponent isLoading={isLoading} data={devicesMap} />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
