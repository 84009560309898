import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CenterAcquisitions } from 'api/models/stats'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import {
  formatCenterAcquisition,
  formatCurrency,
  formatRate,
  formatSurface
} from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Source } from '@mui/icons-material'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import styled from '@emotion/styled'

const BoxStats = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  ' tbody tr:last-child td': {
    background: `${convertHexToRgbA(theme.palette.secondary.dark, 15)}`,
    fontWeight: 'bold'
  },
  ' tbody tr:last-child td.odd': {
    background: `${convertHexToRgbA(theme.palette.secondary.dark, 25)}`,
    fontWeight: 'bold'
  },
  'td, th': {
    textAlign: 'center'
  },
  'td.odd, th.odd': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[100]
  },
  'th:not(.odd) + th.odd, th.odd + th:not(.odd), td:not(.odd) + td.odd, td.odd + td:not(.odd)': {
    borderLeft: '1px solid rgba(255, 255, 255, 0.12)'
  }
}))

export const StatsCenterAcquisitionsView = () => {
  const { t } = useTranslation()
  const { useGetStatsProductionByCenters, setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()
  if (!searchParams.get('type')) {
    searchParams.set('type', 'FULL_DESKTOP')
  }
  const [rows, setRows] = useState<CenterAcquisitions>()
  const { data, isLoading } = useGetStatsProductionByCenters()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['main_services_types', 'type']])
  )

  const [headerGroups, columns] = useMemo(
    () => [
      [
        { label: t('center') },
        { label: t('opp') },
        { label: t('estimate'), colSpan: 2 },
        { label: t('visits'), colSpan: 3 },
        { label: t('contracts'), colSpan: 4 },
        { label: t('value'), colSpan: 2 },
        { label: t('commitment'), colSpan: 1 },
        { label: t('surface'), colSpan: 3 }
      ],
      [
        {
          value: 'center',
          label: (
            <Tooltip title={t('tp_acquisition_for_center')}>
              <span>
                <Source fontSize={'small'} />
              </span>
            </Tooltip>
          ),
          width: 90,
          format: formatCenterAcquisition
        },
        {
          value: 'opportunities',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_opportunities')}>
              <span>{t('span_opportunities')}</span>
            </Tooltip>
          ),
          className: 'odd'
        },
        {
          value: 'quotations',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_quotations')}>
              <span>{t('span_quotations')}</span>
            </Tooltip>
          )
        },
        {
          value: 'quotationAverage',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_quotation_average')}>
              <span>{t('span_quotation_average')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatCurrency(value, '', 0)
        },
        {
          value: 'visits',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_visits')}>
              <span>{t('span_visits')}</span>
            </Tooltip>
          ),
          className: 'odd'
        },
        {
          value: 'futureVisits',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_future_visits')}>
              <span>{t('span_future_visits')}</span>
            </Tooltip>
          ),
          className: 'odd'
        },
        {
          value: 'visitTransfo',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_visit_transformation')}>
              <span>{t('span_visit_transformation')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatRate(value, true),
          className: 'odd'
        },
        {
          value: 'contractSigned',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_signed_contracts')}>
              <span>{t('span_signed_contracts')}</span>
            </Tooltip>
          )
        },
        {
          value: 'contractPaid',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_paid_contracts')}>
              <span>{t('span_paid_contracts')}</span>
            </Tooltip>
          )
        },
        {
          value: 'contractTransfo',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_contract_transformation')}>
              <span>{t('span_contract_transformation')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatRate(value, true)
        },
        {
          value: 'contractVisits',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_contract_visits')}>
              <span>{t('span_contract_visits')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatRate(value, true)
        },
        {
          value: 'value',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_value')}>
              <span>{t('span_value')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatCurrency(value, '', 0),
          className: 'odd'
        },
        {
          value: 'valueAverage',
          toFixed: 0,
          label: (
            <Tooltip title={t('tp_acquisition_for_center_average_value')}>
              <span>{t('span_average_value')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatCurrency(value, '', 0),
          className: 'odd'
        },
        {
          value: 'commitmentAverage',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_average_commitment')}>
              <span>{t('span_average_commitment')}</span>
            </Tooltip>
          ),
          format: (months: number) => `${months} mois`
        },
        {
          value: 'capacity',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_capacity')}>
              <span>{t('span_capacity')}</span>
            </Tooltip>
          ),
          className: 'odd'
        },
        {
          value: 'surface',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_surface')}>
              <span>{t('span_surface')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatSurface(value, '', true),
          className: 'odd'
        },
        {
          value: 'surfaceAverage',
          label: (
            <Tooltip title={t('tp_acquisition_for_center_average_surface')}>
              <span>{t('span_average_surface')}</span>
            </Tooltip>
          ),
          format: (value: number) => formatSurface(value, '', true),
          className: 'odd'
        }
      ]
    ],
    [t]
  )
  const map = useMemo(() => {
    const map = new Map()
    map.set('items', columns)
    map.set('data', rows ?? [])
    return map
  }, [rows, columns])

  const refreshList = useCallback(async () => {
    if (!searchParams.get('begin') || !searchParams.get('end')) {
      const now = dayjs()
      const begin = now.startOf('year')
      const end = now.endOf('year')
      searchParams.set('begin', begin.format('YYYY-MM-DD'))
      searchParams.set('end', end.format('YYYY-MM-DD'))
    }
  }, [])

  useEffect(() => {
    if (data) {
      setRows(Object.values(data))
    }
    initFilters(commonFilters).then(() => refreshList())
  }, [data])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('performance_center')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="deadline"
            label={t('Date')}
            slugs={{ begin: 'begin', end: 'end' }}
            valueLabel={dates.LAST_12_MONTHS}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            ranges={[
              dates.CURRENT_MONTH,
              dates.LAST_MONTH,
              dates.LAST_3_MONTHS,
              dates.LAST_6_MONTHS,
              dates.LAST_12_MONTHS,
              dates.ROLLING_12_MONTHS,
              dates.CURRENT_YEAR,
              dates.NEXT_YEAR,
              dates.LAST_YEAR,
              dates.TWO_YEARS_AGO
            ]}
            allowCustom={true}
            isNoDate={false}
          />
        </FiltersBox>
      </Grid>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <BoxStats>
          <ListSheetComponent columnsGroups={headerGroups} data={map} isLoading={isLoading} />
        </BoxStats>
      )}
    </Container>
  )
}
