import { type Ctx, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import { EmailTemplateSheetType, EmailTemplatesListType } from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { EmailTemplateForm } from 'api/models/forms/email_template'

export const emailTemplateRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<EmailTemplatesListType>({
      url: `${BASE_URL}${API.EMAIL_TEMPLATES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: number) =>
    fetcher<EmailTemplateSheetType>({
      url: `${BASE_URL}${urlHelper(API.EMAIL_TEMPLATE, { id })}`,
      method: 'GET',
      token: token
    }),
  edit: async (data: EmailTemplateForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.EMAIL_TEMPLATE, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
