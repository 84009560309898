import { z } from 'zod'
import dayjs from 'dayjs'

export const opportunityCreateSchema = z
  .object({
    center: z.string({ coerce: true }),
    city: z.string().or(z.literal('')).optional(),
    capacity: z.number({ coerce: true }).optional(),
    surface: z.number({ coerce: true }).optional(),
    serviceNumber: z.number({ coerce: true }).optional(),
    commitment: z.string({ coerce: true }),
    begin: z.instanceof(dayjs as any),
    end: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    canal: z.number({ coerce: true }).nullable().optional(),
    qualification: z.string().optional(),
    subcanal: z.number({ coerce: true }).nullable().optional(),
    source: z.number({ coerce: true }).nullable().optional(),
    step: z.string({ coerce: true }),
    type: z.string({ coerce: true }),
    staff: z.number({ coerce: true }).min(1),
    individuals: z.string({ coerce: true }).min(1, 'Client is required'),
    prescriber: z.string({ coerce: true }).optional(),
    signatory: z.string({ coerce: true }).optional(),
    decider: z.string({ coerce: true }).optional(),
    status: z.string({ coerce: true }).optional()
  })
  .refine((schema) => {
    return schema.canal === null || (schema.subcanal !== null && schema.source !== null)
  })

export const opportunityUpdateFormSchema = z
  .object({
    center: z.number({ coerce: true }).optional(),
    centerName: z.string().optional(),
    city: z.string().or(z.literal('')),
    type: z.string().nullable().optional(),
    surface: z.number({ coerce: true }).nullable().optional(),
    capacity: z.number({ coerce: true }).nullable().optional(),
    begin: z.instanceof(dayjs as any).optional(),
    commitment: z.number({ coerce: true }).optional(),
    end: z.instanceof(dayjs as any).optional(),
    status: z.string({ coerce: true }).optional(),
    groundRefusal: z.string().nullable().optional(),
    step: z.string({ coerce: true }).optional(),
    qualification: z.string({ coerce: true }).optional(),
    individuals: z.string({ coerce: true }).nullable().optional(),
    prescriber: z.string({ coerce: true }).nullable().optional(),
    staff: z.string({ coerce: true }).optional(),
    serviceNumber: z.number({ coerce: true }).nullable().optional(),
    signatory: z.string({ coerce: true }).nullable().optional(),
    decider: z.string({ coerce: true }).nullable().optional(),
    canal: z.number({ coerce: true }).nullable().optional(),
    subCanal: z.number({ coerce: true }).nullable().optional(),
    source: z.number({ coerce: true }).nullable().optional()
  })
  .refine((schema) => {
    return schema.canal === null || (schema.subCanal !== null && schema.source !== null)
  })
  .transform((data) => ({
    ...data,
    groundRefusal: data.status === '5' ? data.groundRefusal : null
  }))

const quotationCreateFormDataSchema = z.object({
  s1Center: z.string(),
  s2Center: z.string(),
  s3Center: z.string(),
  s4Center: z.string(),
  s1Reduction: z.number(),
  s2Reduction: z.number(),
  s3Reduction: z.number(),
  s4Reduction: z.number(),
  s1IsPriceDegressive: z.boolean(),
  s2IsPriceDegressive: z.boolean(),
  s3IsPriceDegressive: z.boolean(),
  s4IsPriceDegressive: z.boolean(),
  s1Commitment: z.number(),
  s2Commitment: z.number(),
  s3Commitment: z.number(),
  s4Commitment: z.number(),
  s1Deadline: z.string(),
  s2Deadline: z.string(),
  s3Deadline: z.string(),
  s4Deadline: z.string(),
  s1ServiceType: z.string(),
  s2ServiceType: z.string(),
  s3ServiceType: z.string(),
  s4ServiceType: z.string(),
  dueDate: z.string()
})

export type OpportunityCreateFormData = z.infer<typeof opportunityCreateSchema>
export type OpportunityUpdateFormProps = z.infer<typeof opportunityUpdateFormSchema>
export type QuotationCreateFormData = z.infer<typeof quotationCreateFormDataSchema>
