import { Box, Card, CardContent } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { List } from 'app/components/lists/list'
import { Opportunity, OpportunityUsers } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'

interface IServicesProps {
  data: Opportunity
}

export const UsersComponent = ({ data }: IServicesProps) => {
  const [users, setUsers] = useState<OpportunityUsers>([] as OpportunityUsers)
  const { getOpportunityUsers } = useFetcher()
  const { t } = useTranslation()
  const { handleSort, handleFilter, orderBy } = useList()
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    if (!data.id) return
    setIsLoading(true)
    await getOpportunityUsers
      .mutateAsync(data.id)
      .then((data) => setUsers(data))
      .finally(() => setIsLoading(false))
  }, [data])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <Card>
      <CardContent>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <TitleComponent text={t('users')} variant={'h3'} paddingLeft={12} />
        </Box>
        <List
          selectable={false}
          columns={[
            {
              label: t('firstname'),
              slug: 'firstName',
              link: { base: '/individuals', slug: 'id' }
            },
            {
              label: t('lastname'),
              slug: 'lastName',
              link: { base: '/individuals', slug: 'id' }
            },
            {
              label: t('phone'),
              slug: 'phone'
            },
            {
              label: t('job'),
              slug: 'job'
            }
          ]}
          items={users}
          handleReset={() => handleFilter(fetch, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(fetch, property)}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  )
}
