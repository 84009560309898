import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import type { Occupancy, OccupancySum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatNumbers, formatSurface } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { Description, Groups, People, SquareFoot, TextSnippet } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'

export const OccupancyView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const {
    getServicesOccupancy,
    getServicesOccupancySum,
    getServicesOccupancyExport,
    searchParams,
    setSearchParams
  } = useFetcher()
  const [servicesOccupancy, setServicesOccupancy] = useState<Occupancy[]>([])
  const [summary, setSummary] = useState<OccupancySum>({} as OccupancySum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['services_types', 'type'],
      ['occupancy_types', 'occupancyType']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getServicesOccupancy
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setServicesOccupancy(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getServicesOccupancySum
        .mutateAsync()
        .then((sum) => {
          setSummary(sum)
        })
        .finally(() => setSummaryIsLoading(false))
    },
    [getServicesOccupancySum, getServicesOccupancy, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('capacity', formatNumbers(summary.capacity ?? 0))
    newSummaryItems.set('occupants', formatNumbers(summary.occupants ?? 0))
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then()
  }, [])

  const exportToPDF = useCallback(async () => {
    await getServicesOccupancyExport.mutateAsync()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('services_occupancy_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <Button variant="contained" startIcon={<TextSnippet />} onClick={exportToPDF}>
            {t('export')}
          </Button>
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {servicesOccupancy.length} / {total}
          </Typography>
        </Grid>
        <List
          items={servicesOccupancy}
          columns={[
            { label: t('label'), slug: 'label' },
            { label: t('center'), slug: 'center' },
            { label: t('type'), slug: 'type' },
            { label: People, slug: 'capacity', tooltip: t('capacity') },
            { label: Groups, slug: 'occupants', tooltip: t('occupants') },
            {
              label: SquareFoot,
              slug: 'surface',
              valueFormatter: formatSurface,
              tooltip: t('surface')
            },
            {
              label: t('enterprise'),
              slug: 'enterpriseReference',
              link: { base: '/enterprises', slug: 'enterpriseId' }
            },
            {
              label: Description,
              slug: 'contractReference',
              tooltip: t('reference'),
              link: { base: '/contracts', slug: 'contractId' }
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {servicesOccupancy.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
