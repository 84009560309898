import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, Paper as MuiPaper, Button } from '@mui/material'
import { Container } from '@mui/system'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Right, rightSchema } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import { FormProvider, useForm } from 'react-hook-form'

export const RightEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { getRight, editRight } = useFetcher()
  const [right, setRight] = useState({} as Right)
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const methods = useForm<Right>({
    mode: 'onChange',
    resolver: zodResolver(rightSchema.refine(() => true))
  })

  const { isDirty, isValid } = methods.formState

  const init = async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getRight,
      data: id ? Number(id) : 0,
      onSuccess: (data: Right) => {
        setRight(data)
        methods.reset(data)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    init().then()
  }, [])

  const handleSubmit = useCallback(
    async (data: Right) => {
      if (!isDirty) return navigate(`/rights`)
      await handleMutation({
        confirm: {
          content: t('confirm_update_right')
        },
        mutation: editRight,
        data: data,
        onSuccess: () => {
          navigate(`/rights`)
        }
      })
    },
    [editRight, isDirty, handleMutation, navigate, t]
  )

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('rights_update')}
        </Typography>
      </Box>
      <Grid>
        <MuiPaper elevation={0} sx={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
          <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
            <FormProvider {...methods}>
              <Grid
                container
                rowSpacing={8}
                columnSpacing={{ xs: 2, sm: 4, md: 8 }}
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item xs={12} md={6}>
                  <FormCard
                    isLoading={isLoading}
                    title={`${right.resource}/${right.role}`}
                    control={methods.control}
                    items={[
                      {
                        type: 'textfield',
                        label: t('resource'),
                        name: 'resource',
                        inputProps: { disabled: true }
                      },
                      {
                        type: 'textfield',
                        label: t('role'),
                        name: 'role',
                        inputProps: { disabled: true }
                      },
                      {
                        type: 'checkbox',
                        label: t('access'),
                        name: 'isDisplay',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('details'),
                        name: 'isShow',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('add'),
                        name: 'isAdd',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('edit'),
                        name: 'isEdit',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('delete'),
                        name: 'isDelete',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('exportable'),
                        name: 'isExportable',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('regenerable'),
                        name: 'isRegenerable',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      },
                      {
                        type: 'checkbox',
                        label: t('all_centers'),
                        name: 'isAllCenters',
                        xs: 12,
                        sx: { padding: '0px', label: { display: 'flex', flexDirection: 'revert' } }
                      }
                    ]}
                  />
                </Grid>
                <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
                  <Grid item xs={6} textAlign={'right'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => navigate(`/rights`)}
                    >
                      {t('cancel')}
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={'left'}>
                    <Button
                      disabled={!isValid}
                      variant={'contained'}
                      color={'primary'}
                      onClick={methods.control.handleSubmit(handleSubmit)}
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </MuiPaper>
      </Grid>
    </Container>
  )
}
