import { z } from 'zod'
import { itemListSchema } from './commons'

const VariousOperationSchema = z.object({
  displayOrder: z.string(),
  id: z.number(),
  journalCode: z.string(),
  documentDate: z.string(),
  thirdParty: z.string(),
  accountingAccountNumber: z.string(),
  typeOfPiece: z.string(),
  label: z.string(),
  debitAmount: z.number(),
  creditAmount: z.number(),
  proofNumber: z.number(),
  vatProfile: z.string(),
  settlementMethod: z.string(),
  company: z.string(),
  letteringCode: z.string()
})

const VariousOperationListSchema = itemListSchema(VariousOperationSchema)

export type VariousOperationListType = z.infer<typeof VariousOperationListSchema>
