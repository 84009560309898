import { z, ZodType } from 'zod'

const acquisitionCostSchema = z.object({
  id: z.number(),
  type: z.string(),
  center: z.string(),
  canal: z.string(),
  subcanal: z.string(),
  source: z.string(),
  day: z.string(),
  cost: z.number()
})

const acquisitionCostListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(acquisitionCostSchema)
})

export type AcquisitionCost = z.infer<typeof acquisitionCostSchema>
export type AcquisitionCostList = z.infer<typeof acquisitionCostListSchema>

const baseSourceSchema = z.object({
  label: z.string(),
  parent: z.number(),
  link: z.string(),
  id: z.number()
})

export type Source = z.infer<typeof baseSourceSchema> & {
  sources: Source[]
}

const sourceSchema: ZodType<Source> = baseSourceSchema.extend({
  sources: z.lazy(() => sourceSchema.array())
})

const sourcesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(sourceSchema)
})

export type SourcesList = z.infer<typeof sourcesListSchema>

export const createSourceFormSchema = z.object({
  canal: z.object({
    id: z.number(),
    label: z.string()
  }),
  subcanal: z.object({
    id: z.number(),
    label: z.string()
  }),
  source: z.string().optional()
})

export const createSourceFormData = z.object({
  parent: z.number().nullable(),
  label: z.string()
})
export type CreateSourceForm = z.infer<typeof createSourceFormSchema>
export type CreateSourceFormData = z.infer<typeof createSourceFormData>
