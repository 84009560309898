import { type Ctx, fetcher } from 'api'
import { API, BASE_URL } from 'api/constants'
import { PricerDiscountGrids, PricerInformations, ServicesPricer } from 'api/models'

export const pricerRouter = ({ token }: Ctx) => ({
  services: async (id: number) =>
    fetcher<ServicesPricer>({
      url: `${BASE_URL}${API.PRICER_SERVICES.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),

  informations: async (id: number) =>
    fetcher<PricerInformations>({
      url: `${BASE_URL}${API.PRICER_INFORMATIONS.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),

  discountGrids: async (center: number, begin: string) =>
    fetcher<PricerDiscountGrids>({
      url: `${BASE_URL}${API.CONFIGURATION_DISCOUNT_GRIDS}?center=${center}&begin=${begin}`,
      method: 'GET',
      token: token
    })
})
