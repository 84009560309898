import { type Ctx, fetcher } from 'api'
import { API, BASE_URL } from 'api/constants'
import { Activities } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const activitiesRouter = ({ token }: Ctx) => ({
  list: async (type: string, page: number, id?: number, activityType?: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.TYPED_ACTIVITIES, { type, id })}?page=${page}${
        activityType ? '&type=' + activityType : ''
      }`,
      method: 'GET',
      token: token
    }),
  create: async (params: any) =>
    fetcher<any>({
      url: `${BASE_URL}${API.ACTIVITIES}`,
      method: 'POST',
      token: token,
      body: params
    })
})
