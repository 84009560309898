import { z } from 'zod'

const customerReservationSchema = z.object({
  id: z.number(),
  begin: z.string(),
  end: z.string(),
  individual: z.string(),
  individualId: z.number(),
  enterprise: z.string(),
  enterpriseId: z.number(),
  center: z.number(),
  services: z.string(),
  amount: z.number(),
  status: z.string(),
  typeLabel: z.string(),
  statusLabel: z.string(),
  hasLinkToVisor: z.number()
})

export type CustomerReservation = z.infer<typeof customerReservationSchema>

const customerReservationListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(customerReservationSchema)
})

export type CustomerReservationList = z.infer<typeof customerReservationListSchema>

const customerReservationSumSchema = z.object({
  count: z.string(),
  services: z.string(),
  amount: z.number().nullable()
})

export type CustomerReservationSum = z.infer<typeof customerReservationSumSchema>

const customerReservationInformationsSchema = z.object({
  id: z.number(),
  clientLabelledName: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  ownerId: z.number(),
  ownerName: z.string(),
  hasLinkToVisor: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  contractId: z.number(),
  contractReference: z.string(),
  staffId: z.number(),
  staffName: z.string(),
  totalAmount: z.number(),
  services: z.number(),
  type: z.number(),
  typeLabel: z.string(),
  status: z.string(),
  statusLabel: z.string(),
  begin: z.string(),
  end: z.string(),
  comment: z.string(),
  isEditable: z.boolean(),
  canConfirm: z.boolean(),
  canCancel: z.boolean(),
  isEnterprise: z.boolean(),
  isInvoiced: z.boolean()
})

export type CustomerReservationInformations = z.infer<typeof customerReservationInformationsSchema>

const customerReservationService = z.object({
  id: z.number(),
  serviceId: z.number(),
  serviceLabel: z.string(),
  type: z.number(),
  bookingKey: z.string().nullable(),
  begin: z.string(),
  end: z.string(),
  quantity: z.number(),
  reductionPrice: z.number(),
  price: z.number(),
  fees: z.number(),
  canChange: z.boolean()
})

export type CustomerReservationService = z.infer<typeof customerReservationService>

const customerReservationServices = z.array(customerReservationService)

export type CustomerReservationServices = z.infer<typeof customerReservationServices>

const servicePriceSchema = z.object({
  amount: z.number(),
  countReservation: z.number().nullable(),
  isReservationFree: z.boolean(),
  maxFreeReservation: z.number().nullable(),
  price: z.number(),
  reduction: z.number(),
  reductionPrice: z.number(),
  reductionRate: z.number(),
  total: z.number(),
  vat: z.number()
})

export type ServicePrice = z.infer<typeof servicePriceSchema>

const availableDesktopsSearch = z.object({
  center: z.number(),
  begin: z.string(),
  end: z.string()
})

export type AvailableDesktopsSearch = z.infer<typeof availableDesktopsSearch>
