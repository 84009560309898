import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { VariousOperationListType } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useFeedback } from 'app/providers/feedback.provider'
import { RangePicker } from 'app/components/filters/range-picker'
import { List } from 'app/components/lists/list'
import { formatDate, formatCurrency } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import { TextSnippet } from '@mui/icons-material'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from '../../../app/components/lists/list-pagination'

export const AccountingVariousOperationsView = (): React.JSX.Element => {
  const { t } = useTranslation()

  const { user } = useApp()

  const {
    getAccountingVariousOperations,
    getAccountingVariousOperationsExport,
    setSearchParams,
    searchParams
  } = useFetcher()

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['accounting_states', 'isaccounted']])
  )

  const { handleMutation } = useFeedback()

  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    initSort,
    handleSort,
    handleFilter
  } = useList()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [variousOperations, setVariousOperations] = useState<VariousOperationListType>()

  const exportResultsCSV = useCallback(async () => {
    await handleMutation({
      mutation: getAccountingVariousOperationsExport
    })
  }, [getAccountingVariousOperationsExport, handleMutation])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getAccountingVariousOperations,
      onSuccess: (data) => {
        setIsLast(data.last)
        setOffset(data.actual)
        setTotal(data.total)
        data.items.forEach((item) => {
          item.documentDate = formatDate(item.documentDate)
          item.debitAmount = formatCurrency(item.debitAmount)
          item.creditAmount = formatCurrency(item.creditAmount)
        })
        setVariousOperations(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getAccountingVariousOperations, setIsLast, setOffset, setTotal, setIsLoading, handleMutation])

  useEffect(() => {
    initFilters(commonFilters).then(async () => {
      setSearchParams({ ...searchParams, isaccounted: '0' })
      await initSort([{ property: 'documentDate', order: 'asc' }])
    })
  }, [])

  return (
    <>
      <Container>
        <Box marginBottom="1rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('accounting_various_operations')}
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchCenterInput
              slug="center"
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              byPassAllCenters={true}
              initialValue={user?.mainCenter}
            />
            <RangePicker
              slug="document_date"
              label={t('date')}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <Button variant="contained" startIcon={<TextSnippet />} onClick={exportResultsCSV}>
              {t('export')}
            </Button>
          </FiltersBox>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {variousOperations?.items.length} / {total}
          </Typography>
        </Grid>
        <List
          items={variousOperations?.items || []}
          columns={[
            {
              label: t('date'),
              slug: 'documentDate'
            },
            {
              label: t('third_party'),
              slug: 'thirdParty'
            },
            {
              label: t('accounting_number'),
              slug: 'accountingAccountNumber'
            },
            {
              label: t('part_type'),
              slug: 'typeOfPiece'
            },
            {
              label: t('wording'),
              slug: 'label'
            },
            {
              label: t('debit_amount'),
              slug: 'debitAmount'
            },
            {
              label: t('credit_amount'),
              slug: 'creditAmount'
            },
            {
              label: t('part_number'),
              slug: 'proofNumber'
            },
            {
              label: t('payment_mode_label'),
              slug: 'settlementMethod'
            },
            {
              label: t('society'),
              slug: 'company'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={isLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {variousOperations?.items.length ?? 0} / {total}
          </Typography>
        </Grid>
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Container>
    </>
  )
}
