import React, { useCallback, useEffect, useState } from 'react'
import { AccoutingStatement, EnterpriseInformation, FormItemValue } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material'
import { formatCurrency, formatDateWithFormat } from 'app/utils/format'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TimelineInvoiceCard } from 'modules/enterprises/components/timeline-accouting/timeline-invoice-card.component'
import { TimelinePaymentCard } from 'modules/enterprises/components/timeline-accouting/timeline-payment-card.component'
import { TimelineCreditCard } from 'modules/enterprises/components/timeline-accouting/timeline-credit-card.component'

interface IEnterpriseAccountingStatement {
  enterprise: EnterpriseInformation
}

export function EnterpriseAccountingStatement({ enterprise }: IEnterpriseAccountingStatement) {
  const { getEnterpriseAccounting } = useFetcher()
  const [accounting, setAccounting] = useState<AccoutingStatement>({} as AccoutingStatement)
  const { handleMutation } = useFeedback()
  const [selectedCenter, setSelectedCenter] = useState<FormItemValue | undefined>(undefined)
  const [centers, setCenters] = useState<FormItemValue[]>([])
  const { getFilters } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { t } = useTranslation()

  const getCenterFilter = useCallback(() => {
    if (!enterprise) return
    setIsLoading(true)
    getFilters
      .mutateAsync({
        variables: ['client_centers'],
        params: {
          client_centers: {
            enterprise: enterprise.id
          }
        }
      })
      .then((data) => {
        if (data && data.client_centers) {
          setSelectedCenter(
            data.client_centers.values.find((center) => center.id === enterprise.mainCenter) ??
              data.client_centers.values[0]
          )
          setCenters(data.client_centers.values)
          setIsLoading(false)
        }
      })
  }, [enterprise.id])

  const getAccountingStatement = useCallback(() => {
    if (!selectedCenter) return
    if (!enterprise) return
    handleMutation({
      onStart: () => {
        setIsLoading(true)
        setAccounting({} as AccoutingStatement)
      },
      mutation: getEnterpriseAccounting,
      data: { id: enterprise.id, centerId: Number(selectedCenter.id) },
      onSuccess: (data) => {
        setAccounting(data)
        setIsLoading(false)
      }
    }).then()
  }, [enterprise.id, selectedCenter])

  const handleChangeCenter = useCallback(
    (event: SelectChangeEvent) => {
      if (selectedCenter && selectedCenter.id === event.target.value) return
      setSelectedCenter(centers.find((center) => center.id === event.target.value))
    },
    [centers, selectedCenter]
  )

  useEffect(() => {
    getCenterFilter()
  }, [getCenterFilter])

  useEffect(() => {
    getAccountingStatement()
  }, [getAccountingStatement])

  if (centers.length === 0 && !isLoading) {
    return (
      <Typography align="center" mt={8}>
        {t('no_invoice_data')}
      </Typography>
    )
  }

  return (
    <>
      <Grid container marginBottom={6}>
        <Grid item xs={12} md={3}>
          {centers && (
            <FormControl fullWidth size={'small'}>
              <Select
                value={selectedCenter?.id ? String(selectedCenter?.id) : ''}
                onChange={handleChangeCenter}
              >
                {centers.map((center) => {
                  return (
                    <MenuItem key={center.id} value={center.id}>
                      {center.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Box>
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <Typography variant={'caption'} textAlign={'center'}>
                {t('balance')}
              </Typography>
              {isLoading ? (
                <Skeleton variant={'rounded'} width={120} height={32} />
              ) : (
                <Paper
                  variant={'outlined'}
                  sx={{
                    borderColor: accounting.balance < 0 ? 'error.main' : undefined,
                    color: accounting.balance < 0 ? 'error.main' : undefined
                  }}
                  elevation={3}
                >
                  <Box padding={2} width={120}>
                    <Typography fontWeight={'bold'} fontSize={'title2'} textAlign={'center'}>
                      {accounting.balance && formatCurrency(accounting.balance)}
                    </Typography>
                  </Box>
                </Paper>
              )}
            </TimelineSeparator>
            <TimelineContent></TimelineContent>
          </TimelineItem>
          {isLoading && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <CircularProgress />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          )}
          {accounting?.items?.map((item: any, index: number) => {
            return (
              <>
                {(index === 0 ||
                  !dayjs(accounting.items[index - 1].effectDate).isSame(
                    item.effectDate,
                    'month'
                  )) && (
                  <TimelineItem key={index + '_month'}>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <Typography style={{ width: '120px' }} textAlign={'center'}>
                        {formatDateWithFormat(item.effectDate, 'MMMM YYYY')}
                      </Typography>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent></TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem key={index} sx={{ height: [140, 85] }}>
                  <TimelineOppositeContent>
                    {item.typeId === 0 && <TimelineInvoiceCard item={item} />}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {item.typeId === 2 && <TimelinePaymentCard item={item} />}
                    {item.typeId === 1 && <TimelineCreditCard item={item} />}
                  </TimelineContent>
                </TimelineItem>
              </>
            )
          }, null)}
        </Timeline>
      </Box>
    </>
  )
}
