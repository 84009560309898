import { Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import type { CustomerReservationInformations, FormItems } from 'api/models'
import {
  formatCurrency,
  formatCustomerReservationStatus,
  formatDateWithTime
} from 'app/utils/format'
import React, { useCallback, useMemo, useRef } from 'react'
import { TitleComponent } from 'app/components/titles/title.component'
import { CustomerReservationServicesList } from 'modules/clientServices/components/customer_reservation-services.component'
import { useApp } from 'app/providers/app.provider'
import { CustomerReservationEdit } from 'modules/clientServices/components/customer_reservation-edit.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import InfoCard, {
  CardItem,
  CardItemName,
  InfoCardColumns
} from 'app/components/card/info-card.component'

interface ICustomerReservationDetailsProps {
  customerReservation: CustomerReservationInformations
  options: FormItems
  children?: React.ReactNode
  hasVisorAccess: boolean
}

export const CustomerReservationDetails = ({
  customerReservation,
  options,
  hasVisorAccess
}: ICustomerReservationDetailsProps) => {
  const { t } = useTranslation()
  const { cancelCustomerReservation, confirmCustomerReservation } = useFetcher()
  const dialogRef = useRef<DialogRef>(null)
  const { user } = useApp()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()

  const openEditDialog = useCallback(() => {
    dialogRef?.current?.open()
  }, [dialogRef])

  const cancel = useCallback(async () => {
    await handleMutation({
      confirm: {
        variant: customerReservation.isInvoiced ? 'warning' : 'primary',
        content: (
          <Typography textAlign="center" fontSize={'inherit'}>
            {t('confirm_cancel_reservation')}
            <Typography fontSize={'inherit'} fontWeight={'bold'}>
              {customerReservation.isInvoiced && t('confirm_cancel_reservation_credit')}
            </Typography>
          </Typography>
        )
      },
      mutation: cancelCustomerReservation,
      data: customerReservation.id,
      toastSuccess: t('cancel_customer_reservation_success'),
      toastError: t('cancel_customer_reservation_error'),
      onSuccess: () => navigate(0)
    })
  }, [customerReservation, navigate, cancelCustomerReservation])

  const confirm = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_reservation')
      },
      mutation: confirmCustomerReservation,
      data: customerReservation.id,
      toastSuccess: t('confirm_customer_reservation_success'),
      toastError: t('confirm_customer_reservation_error'),
      onSuccess: () => navigate(0)
    })
  }, [customerReservation, navigate, confirmCustomerReservation])

  const items = useMemo<InfoCardColumns>(() => {
    return [
      { label: t('center'), value: customerReservation.centerName },
      {
        label: t('client'),
        value: customerReservation.clientName,
        link: `${customerReservation.isEnterprise ? '/enterprises/' : '/individuals/'}${
          customerReservation.clientId
        }`
      },
      {
        label: t('owner'),
        value: customerReservation.ownerName,
        link: `/individuals/${customerReservation.ownerId}`,
        extra: hasVisorAccess && customerReservation.hasLinkToVisor !== 1 && (
          <Typography variant="body2" color={'error'}>
            {t('is_not_client')}
          </Typography>
        )
      },
      {
        custom: (
          <CardItem key={'created'}>
            <Stack>
              <Typography variant="caption" sx={{ opacity: 0.85 }}>
                {t('created_at')}
              </Typography>
              <CardItemName variant="body2">
                {formatDateWithTime(customerReservation.createdAt)}
              </CardItemName>
            </Stack>
          </CardItem>
        )
      },
      {
        label: t('contract'),
        value: customerReservation.contractReference,
        link: `/contracts/${customerReservation.contractId}`
      },
      {
        custom: (
          <CardItem key={'updated'}>
            <Stack>
              <Typography variant="caption" sx={{ opacity: 0.85 }}>
                {t('updated_at')}
              </Typography>
              <CardItemName variant="body2">
                {formatDateWithTime(customerReservation.updatedAt)}
              </CardItemName>
            </Stack>
          </CardItem>
        )
      },
      {
        label: customerReservation.staffId ? t('staff') : '',
        value: customerReservation.staffName
      },
      { label: t('amount'), value: formatCurrency(customerReservation.totalAmount) },
      { label: t('services'), value: customerReservation.services },
      { label: t('type'), value: customerReservation.typeLabel },
      {
        label: t('status'),
        custom: (
          <Stack key={'status'} component={'span'}>
            <Typography variant="caption" sx={{ opacity: 0.85 }}>
              {t('status')}
            </Typography>
            <Stack component={'span'} direction={'row'} alignItems={'center'}>
              <CardItemName variant="body2">
                {formatCustomerReservationStatus(
                  customerReservation.status,
                  customerReservation.statusLabel
                )}
              </CardItemName>
              <CardItemName>{customerReservation.statusLabel}</CardItemName>
            </Stack>
          </Stack>
        )
      },
      { label: t('begin'), value: formatDateWithTime(customerReservation.begin) },
      { label: t('end'), value: formatDateWithTime(customerReservation.end) }
    ]
  }, [customerReservation, hasVisorAccess])

  return (
    <InfoCard
      title={t('informations')}
      titleAction={{ icon: Edit, onClick: openEditDialog }}
      columns={items}
    >
      <Grid container padding={4} spacing={4}>
        {customerReservation.isEditable &&
          user?.rights.customer_reservation.isEdit &&
          customerReservation.canConfirm && (
            <Grid item xs={'auto'}>
              <Button size={'small'} color={'primary'} variant={'contained'} onClick={confirm}>
                {t('confirm_reservation')}
              </Button>
            </Grid>
          )}
        {user?.rights.customer_reservation.isEdit && customerReservation.canCancel && (
          <Grid item xs={'auto'}>
            <Button size={'small'} color={'warning'} variant={'contained'} onClick={cancel}>
              {t('cancel_reservation')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Box marginBottom={4} marginTop={4} paddingLeft={4}>
        <TitleComponent text={t('comment')} variant={'h3'} paddingTop={0} paddingLeft={12} />
        <Typography paddingLeft={4}>{customerReservation.comment}</Typography>
      </Box>
      <Divider />
      <CustomerReservationServicesList
        customerReservation={customerReservation}
        options={options}
        hasVisorAccess={hasVisorAccess}
      />
      <CustomerReservationEdit
        customerReservation={customerReservation}
        dialogRef={dialogRef}
        options={options}
      />
    </InfoCard>
  )
}
