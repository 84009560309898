import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useTranslation } from 'react-i18next'

type IControlledTextFieldProps = {
  type?: HTMLInputTypeAttribute
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
} & TextFieldProps

export function ControlledTextField({
  type,
  name,
  label,
  control,
  icon: Icon,
  required,
  handleChange,
  ...otherProps
}: IControlledTextFieldProps) {
  const { t } = useTranslation()
  return (
    <Controller
      render={({ field: { name, ref, value, onChange }, formState: { errors } }) => {
        return (
          <FormControl fullWidth>
            <TextField
              required={required}
              type={type || 'text'}
              InputLabelProps={{ shrink: true }}
              error={!!errors[name]}
              helperText={errors[name] ? t(`error_${errors[name]?.type}`) : null}
              InputProps={{
                startAdornment: Icon ? (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ) : null
              }}
              size={'small'}
              inputRef={ref}
              fullWidth
              label={`${label ? label : ''}`}
              id={name}
              data-cy={`input-${name}`}
              value={value ?? ''}
              onChange={(e) => {
                onChange(e)
                if (handleChange) handleChange(e)
              }}
              {...otherProps}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
