import { z } from 'zod'
import { itemListSchema } from './commons'

const messageSchema = z.object({
  id: z.number(),
  client: z.string(),
  clientId: z.number(),
  type: z.string(),
  contact: z.string(),
  sendingDate: z.string(),
  subject: z.string(),
  status: z.string()
})

export type Message = z.infer<typeof messageSchema>

const messageListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(messageSchema)
})

export type MessageList = z.infer<typeof messageListSchema>

const authentificationRadiusSchema = z.object({
  id: z.number(),
  username: z.string(),
  pass: z.string(),
  reply: z.string(),
  authdate: z.string(),
  class: z.string()
})

const authentificationRadius = itemListSchema(authentificationRadiusSchema)
export type AuthentificationRadiusList = z.infer<typeof authentificationRadius>
export type AuthentificationRadius = z.arrayOutputType<typeof authentificationRadiusSchema>
