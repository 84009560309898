import { z } from 'zod'
import dayjs from 'dayjs'

export const eventFormDataSchema = z.object({
  type: z.number({ coerce: true }),
  staff: z.number({ coerce: true }).min(1).default(0),
  staffName: z.string({ coerce: true }).optional().nullable(),
  status: z.number({ coerce: true }),
  begin: z.instanceof(dayjs as any).optional(),
  end: z.instanceof(dayjs as any).optional(),
  enterprise: z.number({ coerce: true }).optional(),
  enterpriseName: z.string({ coerce: true }).optional().nullable(),
  owner: z.number({ coerce: true }).optional(),
  ownerName: z.string({ coerce: true }).optional().nullable(),
  opportunity: z.number({ coerce: true }).optional(),
  center: z.number({ coerce: true }).optional(),
  comment: z.string().optional(),
  groundRefusal: z.number({ coerce: true }).optional()
})
export type EventFormData = z.infer<typeof eventFormDataSchema>
