import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  QuotationInformation,
  QuotationsList,
  QuotationSolutionPrice,
  QuotationSolutions,
  SelectCommitments
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { UpdateQuotationFormItems } from 'api/models/forms/quotations'

export const quotationsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<QuotationsList>({
      url: `${BASE_URL}${API.QUOTATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: number) =>
    fetcher<QuotationInformation>({
      url: `${BASE_URL}${API.QUOTATIONS}/${id}`,
      method: 'GET',
      token: token
    }),
  getPdf: async (id: number) =>
    downloader(
      {
        url: `${BASE_URL}${urlHelper(API.QUOTATION_DOWNLOAD, { id: id })}`,
        method: 'GET',
        token: token
      },
      'quotation_' + id + '.pdf'
    ),
  getSolutions: async (id: number) =>
    fetcher<QuotationSolutions>({
      url: `${BASE_URL}${urlHelper(API.QUOTATION_SOLUTIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  cancel: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_CANCEL, { id })}`,
      method: 'PATCH',
      token: token
    }),
  send: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_QUOTATION_SEND, { id })}`,
      method: 'POST',
      token: token
    }),
  update: async (data: UpdateQuotationFormItems) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_UPDATE, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data.data
    }),
  transformSolution: async (data: { id: number; solutionId: number }) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_QUOTATION_SOLUTION_TRANSFORM, {
        id: data.id,
        solutionId: data.solutionId
      })}`,
      method: 'POST',
      token: token
    }),
  updateSolution: async (data: { id: number; solutionId: number; data: any }) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_SOLUTION, {
        id: data.id,
        solutionId: data.solutionId
      })}`,
      method: 'PATCH',
      token: token,
      body: data.data
    }),
  priceSolution: async (data: { id: number; solutionId: number; data: any }) =>
    fetcher<QuotationSolutionPrice[]>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_SOLUTION_PRICE, {
        id: data.id,
        solutionId: data.solutionId
      })}?${new URLSearchParams(data.data)}`,
      method: 'GET',
      token: token
    }),
  getSelectCommitments: async (id: number, solutionId: number) =>
    fetcher<SelectCommitments>({
      url: `${BASE_URL}${urlHelper(API.QUOTATION_SOLUTION_SELECT_COMMITMENTS, { id, solutionId })}`,
      method: 'GET',
      token: token
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_QUOTATIONS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      `quotations_${new Date().toLocaleDateString()}.csv`
    )
})
