import React, { useCallback, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { formatCurrency, formatDate } from 'app/utils/format'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Option } from 'api/models'
import { useTranslation } from 'react-i18next'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'

export const OptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getOptions, searchParams, setSearchParams } = useFetcher()
  const [options, setOptions] = useState<Option[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['option_type', 'type'],
      ['option_expired', 'expired']
    ])
  )

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getOptions
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setOptions(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getOptions, setIsLast, setOffset, setTotal]
  )

  const feesFormatter = useCallback((item: Option) => {
    if (item['fees'] > 0) {
      return item['fees']
    }
    if (item['freeFees'] !== 0) {
      return item['freeFees']
    }
    return ''
  }, [])

  const priceFormatter = useCallback((item: Option) => {
    if (item['price'] > 0) {
      return item['price']
    }
    if (item['freePrice'] !== 0) {
      return item['freePrice']
    }
    return ''
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('options')}
          {getRight('catalog_option', 'isAdd') && (
            <IconButton
              title={t('add_options')}
              aria-label={t('add_options')}
              color="primary"
              onClick={() => navigate('/services/options/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {options.length} / {total}
          </Typography>
        </Grid>
        <List
          items={options}
          columns={[
            { label: t('label'), slug: 'label', link: { base: '/options', slug: 'id' } },
            { label: t('type'), slug: 'type' },
            {
              label: t('installation_costs'),
              slug: 'fees',
              valueFormatter: formatCurrency,
              condition: feesFormatter
            },
            {
              label: t('monthly_price'),
              slug: 'price',
              valueFormatter: formatCurrency,
              condition: priceFormatter
            },
            { label: t('begin_at'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end_at'), slug: 'end', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {options.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
