import type { EnterpriseInformation } from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useApp } from 'app/providers/app.provider'
import { EnterpriseSsidUsersComponent } from 'modules/enterprises/components/enterprise_ssid_users.component'
import { EnterpriseSsidAddForm } from './enterprise_ssid_add_form.component'

interface IEnterpriseSsidProps {
  enterprise: EnterpriseInformation
}

export function EnterpriseSsidComponent({ enterprise }: IEnterpriseSsidProps) {
  const { getEnterpriseSsids } = useFetcher()
  const { getRight } = useApp()

  const [isLoading, setIsLoading] = useState(false)
  const [ssids, setSsids] = useState<any>([])

  const [isRadiusSsidUserLinkRight] = useState<boolean>(
    getRight('radius_ssid_user_link', 'isDisplay')
  )
  const [isRadiusSsidRight] = useState<boolean>(getRight('radius_ssid', 'isAdd'))

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getEnterpriseSsids.mutateAsync(enterprise.id)
      setSsids(data)
    } finally {
      setIsLoading(false)
    }
  }, [enterprise.id])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <>
      {isRadiusSsidRight && (
        <EnterpriseSsidAddForm
          enterprise={enterprise}
          ssids={ssids}
          isLoading={isLoading}
          refreshList={refreshList}
        />
      )}
      {isRadiusSsidUserLinkRight && (
        <EnterpriseSsidUsersComponent enterprise={enterprise} ssids={ssids} isLoading={isLoading} />
      )}
    </>
  )
}
