import { Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractIndexingAnniversaryList } from 'api/models'
import { formatCurrency, formatDate, formatRate } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'

interface IIndexingProps {
  indexingValues: ContractIndexingAnniversaryList
  isLoading: boolean
}

export const IndexingComponent = ({ indexingValues, isLoading }: IIndexingProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('indexing_date').toUpperCase(), value: 'date', format: formatDate },
        { label: t('rate').toUpperCase(), value: 'rate', format: formatRate },
        { label: t('old_price').toUpperCase(), value: 'oldPrice', format: formatCurrency },
        { label: t('new_price').toUpperCase(), value: 'newPrice', format: formatCurrency }
      ])
      map.set('data', indexingValues)
      return map
    })
  }, [indexingValues])
  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('anniversary_indexing')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={initMap} />
      </CardContent>
    </Card>
  )
}
