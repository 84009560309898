import { z } from 'zod'

const checkoutSchema = z.object({
  contractId: z.number(),
  contractReference: z.string(),
  contractDeadline: z.string(),
  contractPriorNotice: z.string(),
  clientName: z.string(),
  clientId: z.number(),
  centerName: z.string(),
  contractCommitment: z.number(),
  contractSurface: z.number(),
  contractPrice: z.number(),
  contractSquareMeterPrice: z.number(),
  endorsementId: z.number(),
  endorsementReference: z.string(),
  endorsementState: z.number(),
  endorsementReason: z.number(),
  endorsementStaff: z.string(),
  endorsementStaffLight: z.string(),
  endorsementCommitment: z.number(),
  endorsementSurface: z.number(),
  endorsementPrice: z.number(),
  endorsementSquareMeterPrice: z.number(),
  endorsementBegin: z.string(),
  amountDifference: z.number(),
  surfaceDifference: z.number(),
  commitmentDifference: z.number(),
  guaranteesDifference: z.number(),
  contractTypeLabel: z.string(),
  contractType: z.string(),
  endorsementReasonLabel: z.string(),
  endorsementStateLabel: z.string(),
  squareMeterDifference: z.string()
})

export type Checkout = z.infer<typeof checkoutSchema>

const checkoutListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(checkoutSchema)
})

export type CheckoutList = z.infer<typeof checkoutListSchema>

const checkoutSumSchema = z.object({
  contractCount: z.number(),
  endorsementCount: z.number(),
  contractPriceSum: z.number(),
  contractSurfaceSum: z.number(),
  endorsementCommitmentDifference: z.string(),
  endorsementAmountDifference: z.number(),
  endorsementSurfaceDifferenceSum: z.number()
})

export type CheckoutSum = z.infer<typeof checkoutSumSchema>
