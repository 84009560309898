import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, Container, useTheme } from '@mui/material'
import { PresenceConsumption, PresenceConsumptionSum } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { useFeedback } from 'app/providers/feedback.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { AccessTimeFilled } from '@mui/icons-material'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { Link as RouterLink } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'

export const PresenceConsumptionView = () => {
  const { searchParams, setSearchParams } = useFetcher()
  const theme = useTheme()
  const user = localStorage.getItem('user')
  if (!searchParams.get('center') && user && JSON.parse(user).mainCenter) {
    searchParams.set('center', JSON.parse(user).mainCenter)
  }
  const { getPresenceConsumption, getPresenceConsumptionSum } = useFetcher()
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [summary, setSummary] = useState<PresenceConsumptionSum>({} as PresenceConsumptionSum)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [attributionConsumption, setAttributionConsumption] = useState<PresenceConsumption[]>([])
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleFilter, handleSort } =
    useList()
  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getPresenceConsumption,
        onSuccess: (data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setAttributionConsumption(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setIsLoading(false)
      })
      await handleMutation({
        onStart: () => setSummaryIsLoading(true),
        mutation: getPresenceConsumptionSum,
        onSuccess: (sum) => {
          setSummary(sum)
        },
        onEnd: () => setSummaryIsLoading(false)
      })
    },
    [getPresenceConsumption, setIsLast, setOffset, setTotal, setIsLoading, handleMutation]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('total_hours', `${String(summary.sum)}h`)
    setSummaryItems(newSummaryItems)
  }, [summary])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('List_of_presence_consumption')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
          groupBy={['subcategory']}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            withDefaultValue
            disableClearable
          />
          <SearchClientInput
            slug="client"
            label={'Clients'}
            defaultIsEnterprise={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="enterprise"
            defaultIsIndividual={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="date"
            label={'Period_of_presence'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            valueLabel={dates.TODAY}
            isNoDate={false}
          />
        </FiltersContainer>
      </Grid>
      <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {attributionConsumption?.length} / {total}
        </Typography>
      </Grid>
      <List
        items={attributionConsumption || []}
        columns={[
          {
            label: t('client'),
            slug: 'individualName'
          },
          {
            label: AccessTimeFilled,
            slug: 'totalTime',
            condition: (item: PresenceConsumption) => {
              return (
                <RouterLink
                  to={`/presences/logs?client=${item.individualId}`}
                  style={{ textDecoration: 'none', color: theme.palette.primary.light }}
                >
                  {item.totalTime}h
                </RouterLink>
              )
            }
          },
          {
            label: t('client_consumption'),
            slug: 'totalPercentage',
            condition: (item: PresenceConsumption) => {
              return (
                <LinearProgress
                  variant={'determinate'}
                  value={item.totalPercentage}
                  style={{ height: 10 }}
                />
              )
            }
          }
        ]}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(() => refreshList(true), property)}
        isLoading={isLoading}
      />

      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {attributionConsumption?.length} / {total}
        </Typography>
      </Grid>
      {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
    </Container>
  )
}
