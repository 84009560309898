import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const attributionSchema = z.object({
  id: z.number(),
  labelledName: z.string(),
  reference: z.string(),
  type: z.string(),
  date: z.string(),
  center: z.number()
})

export type Attribution = z.infer<typeof attributionSchema>
export const attributionListSchema = itemListSchema(attributionSchema)
export type AttributionList = z.infer<typeof attributionListSchema>
