import { useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormItem } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { FormCard } from 'app/components/form/form-card.component'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import { Link } from 'app/components/link.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { UpdateAdminContractProps, updateAdminContractSchema } from 'api/models/forms/contract'
import dayjs from 'dayjs'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const ContractEditView = () => {
  const { id } = useParams()
  const { Contracts, FormItems } = useFetcherV2()
  const { data: contract, isLoading: isContractLoading } = useQueryFetcher({
    queryKey: ['contracts', 'get', id],
    queryFn: () => Contracts.getRead(Number(id))
  })
  const { data: invoicingTypes, isLoading: isOptionsLoading } = useQueryFetcher({
    queryKey: ['form-items', 'invoicing-types'],
    queryFn: async () => {
      const options = await FormItems.list(['invoicing_types'])
      if (options.invoicing_types) return options.invoicing_types
      return {} as FormItem
    }
  })

  const { t } = useTranslation()
  const navigate = useNavigate()
  const methods = useForm<UpdateAdminContractProps>({
    mode: 'onChange',
    defaultValues: {
      staff: 0,
      invoicing: ''
    },
    resolver: zodResolver(updateAdminContractSchema)
  })

  const updateContractAdmin = useMutationFetcher({
    confirm: {
      content: t('confirm_update_contract')
    },
    mutationKey: ['contracts', 'update'],
    mutationFn: ({ id, data }: { id: number; data: UpdateAdminContractProps }) => {
      return Contracts.adminUpdate(id, data)
    },
    toastSuccess: t('update_contract_success'),
    onSettled: () => navigate(0)
  })

  const handleSubmit = useCallback(
    async (data: UpdateAdminContractProps) => {
      if (!contract) return
      updateContractAdmin({
        id: contract.id,
        data: data
      })
    },
    [contract]
  )

  const items = useMemo(() => {
    const _items = [
      {
        type: 'staffs',
        label: t('contract_created_by'),
        name: 'staff',
        inputProps: { defaultValue: contract?.staffFirstName + ' ' + contract?.staffLastName }
      },
      { type: 'select', label: t('invoicing'), name: 'invoicing', formItem: invoicingTypes },
      { type: 'datepicker', label: t('end_date_commitment'), name: 'deadline' }
    ] as FormInputProps[]

    if (contract?.end) {
      _items.push({ type: 'datepicker', label: t('end'), name: 'end' })
    }
    return _items as FormInputProps[]
  }, [contract, invoicingTypes, t])

  useEffect(() => {
    if (!contract) return

    methods.setValue('deadline', contract.deadline ? dayjs(contract.deadline) : null)
    methods.setValue('end', contract.end ? dayjs(contract.end) : null)
    methods.setValue('invoicing', String(contract.invoicingType))
  }, [contract])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('update_contract')} |{' '}
          <Link to={`/contracts/${contract?.id}`}>{contract?.reference}</Link>
        </Typography>
        <Typography variant="body2">{contract?.clientName}</Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch">
          <Grid item xs={12}>
            <FormCard
              isLoading={isContractLoading || isOptionsLoading}
              title={t('contract')}
              control={methods.control}
              methods={methods}
              items={items}
            />
          </Grid>

          <Grid item xs={12} textAlign={'center'}>
            <Button
              disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}>
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
