import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material'
import React, {
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect
} from 'react'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { Confirm, ConfirmDialog } from 'app/components/dialog/confirm-dialog.component'
import { useTranslation } from 'react-i18next'
import { UseMutationResult } from '@tanstack/react-query/build/modern'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import { MutationObserverIdleResult, MutationObserverLoadingResult } from '@tanstack/react-query'

type Toast = {
  message: string
  variant: 'success' | 'error' | 'warning' | 'info'
  duration?: number
}

interface IFeedbackContext {
  toast: ({ message, variant }: Toast) => void
  confirm: (confirm: Confirm) => void
  handleMutation: HandleMutationFunction
  handleFetcher: any
}

type HandleMutationFunction = <T, U>(props: HandleMutationOptions<T, U>) => Promise<void>

const FeedbackContext = createContext<IFeedbackContext>({
  toast: () => {},
  confirm: () => {},
  handleMutation: async () => {},
  handleFetcher: async () => {}
})

interface IFeedbackProviderProps {
  children: React.ReactNode
}

type HandleMutationOptions<T, U> = {
  mutation: UseMutationResult<T, any, U, any>
  data?: U
  onStart?: () => void
  onSuccess?: (response: T) => void
  onError?: (error: any) => void
  onEnd?: () => void
  toastSuccess?: string
  toastError?: string
  confirm?: Omit<Confirm, 'onConfirm'>
}

const REFRESH_MESSAGE_DURATION = 1000 * 60 * 60 //1 hour

export const FeedbackProvider = ({ children }: IFeedbackProviderProps): React.JSX.Element => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [variant, setVariant] = useState<'success' | 'error' | 'warning' | 'info'>('success')
  const [duration, setDuration] = useState(6000)
  const [confirmData, setConfirmData] = useState<Confirm | null>(null)
  const confirmRef = useRef<DialogRef>(null)
  const { t } = useTranslation()

  const toast = useCallback(({ message, variant, duration }: Toast) => {
    setOpen(true)
    setMessage(message)
    setVariant(variant)

    if (duration) {
      setDuration(duration)
    }
  }, [])

  const handleClose = useCallback(
    (event?: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return
      }

      setOpen(false)
      setDuration(6000)
    },
    []
  )

  const confirm = useCallback(
    ({ variant = 'primary', ...otherProps }: Confirm) => {
      setConfirmData({
        variant,
        ...otherProps
      })
      confirmRef.current?.open()
    },
    [confirmRef]
  )

  const callMutation = useCallback(
    async (options: HandleMutationOptions<any, any>) => {
      const {
        data,
        mutation,
        onStart,
        onSuccess,
        onError,
        onEnd,
        toastSuccess,
        toastError = t('error')
      } = options

      if (onStart) {
        onStart()
      }

      try {
        const response = await mutation.mutateAsync(data)
        if (onSuccess) {
          onSuccess(response)
        }
        if (toastSuccess) {
          toast({
            message: toastSuccess,
            variant: 'success'
          })
        }
      } catch (error) {
        toast({
          message: toastError,
          variant: 'error'
        })
        if (onError) {
          onError(error)
        }
      } finally {
        if (onEnd) {
          onEnd()
        }
      }
    },
    [t]
  )

  const handleMutation = useCallback(async (options: HandleMutationOptions<any, any>) => {
    const { confirm: confirmOptions } = options

    if (confirmOptions) {
      confirm({ ...confirmOptions, onConfirm: async () => await callMutation(options) })
    } else await callMutation(options)
  }, [])

  const handleFetcher = useCallback(async (options: any) => {
    try {
      if (options.confirm) {
        try {
          await new Promise((resolve, reject) => {
            confirm({
              ...options.confirm,
              onConfirm: async () => {
                const data = await options.func()
                if (options.onSuccess) {
                  options.onSuccess(data)
                }
                resolve(null)
              },
              onCancel: () => reject()
            })
          })
        } catch (err) {
          return
        }
      } else {
        const data = await options.func()
        if (options.onSuccess) {
          options.onSuccess(data)
        }
      }
    } catch (err) {
      toast({
        message: t('error'),
        variant: 'error'
      })
      if (options.onError) {
        options.onError(err)
      }
    } finally {
      if (options.onEnd) {
        options.onEnd()
      }
    }
  }, [])

  const values = useMemo(() => {
    return {
      handleMutation,
      handleFetcher,
      toast,
      confirm
    }
  }, [toast, confirm, handleMutation])

  const showUpdateMessage = useCallback(() => {
    toast({
      variant: 'info',
      message: t('update_found_refresh_message'),
      duration: REFRESH_MESSAGE_DURATION
    })
  }, [toast])

  //Handle bundle update - display toast
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: showUpdateMessage
    })
  }, [])

  return (
    <FeedbackContext.Provider value={values}>
      {children}
      <Snackbar open={open} message={message} onClose={handleClose} autoHideDuration={duration}>
        <Alert severity={variant}>{message}</Alert>
      </Snackbar>
      <ConfirmDialog
        confirmData={confirmData}
        setConfirmData={setConfirmData}
        confirmRef={confirmRef}
      />
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => useContext(FeedbackContext)
