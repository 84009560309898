import { z } from 'zod'

const documentSchema = z.object({
  id: z.number(),
  reference: z.string().nullable().optional(),
  clientId: z.number().nullable().optional(),
  clientName: z.number().nullable().optional(),
  mimeType: z.string().nullable().optional(),
  file: z.string().nullable().optional(),
  status: z.number().nullable().optional(),
  statusLabel: z.string().nullable().optional()
})

export type Document = z.infer<typeof documentSchema>
