import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { FormItems } from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateSourceForm, createSourceFormSchema } from 'api/models/sources'

export const SourceAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItemsWithFilters, createSource } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { handleMutation, confirm } = useFeedback()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['canals', 'source'],
      ['subcanals', 'subCanal']
    ])
  )

  const methods = useForm<CreateSourceForm>({
    mode: 'onChange',
    resolver: zodResolver(createSourceFormSchema),
    defaultValues: {
      canal: { id: 0, label: '' },
      subcanal: { id: 0, label: '' },
      source: ''
    }
  })
  const canal = methods.watch('canal')
  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      await getFormItemsWithFilters
        .mutateAsync({
          filters: Array.from(commonOptions.keys() as any),
          references_filters: { subcanals: { canal: methods.getValues().canal?.id } }
        })
        .then((optionsData) => {
          setFormItems(optionsData)
          if (optionsData.subcanals && optionsData.subcanals.values.length > 0) {
            methods.setValue(
              'subcanal',
              {
                id: Number(optionsData.subcanals.values[0].id),
                label: optionsData.subcanals.values[0].label
              },
              {
                shouldValidate: true
              }
            )
          } else {
            methods.setValue(
              'subcanal',
              { id: 0, label: '' },
              {
                shouldValidate: true
              }
            )
          }
          setIsLoading(false)
        })
    },
    [methods]
  )

  const handleConfirm = useCallback(
    async (data: CreateSourceForm) => {
      let parentId = Number(data.canal.id)
      if (!parentId) {
        console.log('create canal' + data.canal.label)
        await handleMutation({
          mutation: createSource,
          data: {
            parent: null,
            label: data.canal.label
          },
          onSuccess: ({ id }) => {
            parentId = Number(id)
          }
        })
      }

      if (!data.subcanal.id) {
        await handleMutation({
          mutation: createSource,
          data: {
            parent: parentId,
            label: data.subcanal.label
          },
          onSuccess: ({ id }) => {
            parentId = Number(id)
          }
        })
      } else {
        parentId = Number(data.subcanal.id)
      }

      if (data.source) {
        await handleMutation({
          mutation: createSource,
          data: {
            parent: parentId,
            label: data.source
          }
        })
      }

      navigate('/sources')
    },
    [navigate, handleMutation]
  )

  const handleSubmit = async (data: CreateSourceForm) => {
    confirm({
      content: t('confirm_create_source'),
      onConfirm: async () => await handleConfirm(data)
    })
  }

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [canal])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_source')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <FormCard
              isLoading={isLoading}
              title={t('enterprise')}
              control={methods.control}
              items={[
                {
                  type: 'autocomplete',
                  label: t('canal'),
                  name: 'canal',
                  required: true,
                  xs: 4,
                  formItem: formItems.canals,
                  inputProps: {
                    allowNew: true,
                    onChange: (value: any) => {
                      console.log({ value })
                    }
                  }
                },
                {
                  type: 'autocomplete',
                  label: t('subcanal'),
                  name: 'subcanal',
                  required: true,
                  xs: 4,
                  formItem: formItems.subcanals,
                  inputProps: {
                    allowNew: true
                  }
                },
                { type: 'textfield', label: t('source'), name: 'source', required: true, xs: 4 }
              ]}
            />
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={12} textAlign={'center'}>
            <Button
              disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
