import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { ClientRequest, ClientRequestSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { formatClientRequestStatus, formatDateWithTime, formatStaffBadge } from 'app/utils/format'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchStaffInput } from 'app/components/filters/search-staff-input'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const ClientRequestsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getClientRequests, getClientRequestSum, searchParams, setSearchParams } = useFetcher()
  const [clientRequests, setClientRequests] = useState<ClientRequest[]>([])
  const [summary, setSummary] = useState<ClientRequestSum>({} as ClientRequestSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['client_request_category', 'category'],
      ['client_request_subcategory', 'subcategory'],
      ['client_request_status', 'status'],
      ['staff_centers', 'center']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getClientRequests
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setClientRequests(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      getClientRequestSum
        .mutateAsync()
        .then((sum) => setSummary(sum))
        .finally(() => setSummaryIsLoading(false))
    },
    [getClientRequestSum, getClientRequests, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('total', summary.total)
    newSummaryItems.set('created', String(summary.created))
    newSummaryItems.set('inprogress', String(summary.inprogress))
    newSummaryItems.set('treated', String(summary.treated))
    newSummaryItems.set('cancelled', String(summary.cancelled))
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('customer_service')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          groupBy={['subcategory']}
        >
          <SearchClientInput
            slug="enterprise"
            defaultIsIndividual={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="individual"
            defaultIsEnterprise={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="created_at"
            label={'created_at'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchStaffInput
            slug="staff"
            onChange={() => handleFilter(refreshList, true)}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {clientRequests.length} / {total}
          </Typography>
        </Grid>
        <List
          items={clientRequests}
          columns={[
            { label: '#', slug: 'id', link: { base: '/clientrequests', slug: 'id' } },
            {
              label: t('status'),
              slug: 'status',
              text: 'statusLabel',
              valueFormatter: formatClientRequestStatus
            },
            { label: t('center'), slug: 'center' },
            { label: t('enterprise'), slug: 'enterprise' },
            { label: t('individual'), slug: 'individual' },
            { label: t('category'), slug: 'category' },
            { label: t('subcategory'), slug: 'subcategory' },
            { label: t('created_at'), slug: 'begin', valueFormatter: formatDateWithTime },
            { label: t('end'), slug: 'end', valueFormatter: formatDateWithTime },
            {
              label: t('staff'),
              slug: 'staffInitial',
              text: 'staff',
              valueFormatter: formatStaffBadge
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {clientRequests.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
