import { useCallback, useMemo, useState } from 'react'
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField
} from '@mui/material'
import { Switch } from 'app/components/switch/switch'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import type { Client } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'

interface ISearchClientInputProps {
  slug: string
  label?: string
  onChange?: () => void
  setSearchParams: (params: URLSearchParams) => void
  searchParams: URLSearchParams
  defaultIsIndividual?: boolean
  defaultIsEnterprise?: boolean
  canSwitchIndividual?: boolean
  canSwitchEnterprise?: boolean
}

export function SearchClientInput({
  slug,
  label,
  onChange,
  searchParams,
  setSearchParams,
  defaultIsIndividual = true,
  defaultIsEnterprise = true,
  canSwitchEnterprise = false,
  canSwitchIndividual = false
}: ISearchClientInputProps) {
  const { getClientFilters } = useFetcher()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [clients, setClients] = useState<Client[]>([])
  const [isIndividual, setIsIndividual] = useState<boolean>(defaultIsIndividual)
  const [isEnterprise, setIsEnterprise] = useState<boolean>(defaultIsEnterprise)
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [initialId, setInitialId] = useState(searchParams.get(slug))
  const { handleMutation } = useFeedback()

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = String(event.target.value ?? '')
    setInputValue(value)
  }, [])

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => setLoading(true),
        mutation: getClientFilters,
        data: {
          name: inputValue,
          id: Number(initialId),
          is_enterprise: isEnterprise,
          is_individual: isIndividual
        },
        onSuccess: (data) => {
          const _clients = data
            .map((obj: any) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj) => obj !== null)
            .flat()
            .sort((a: Client, b: Client) =>
              a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' })
            ) as unknown as Client[]
          setClients(_clients)

          if (initialId) {
            const _client = _clients.find((c) => Number(c.id) === Number(initialId))
            if (_client) handleOnChange(null, _client)
            setInitialId(null) //Reset initial id
          }
        },
        onEnd: () => setLoading(false)
      })
    },
    [inputValue],
    350
  )

  const handleOnChange = useCallback(
    (event: any, newValue: any) => {
      setSelectedClient(newValue)

      if (newValue) {
        searchParams.set(slug, newValue.id)
      } else {
        searchParams.delete(slug)
      }

      setSearchParams(searchParams)

      if (onChange) {
        onChange()
      }
    },
    [onChange, searchParams, setSearchParams, slug]
  )

  const noOptionsText = useMemo(() => {
    if (inputValue.length < 2) return t('type_at_least_2_characters')
    return t('no_options')
  }, [inputValue, t])

  return (
    <Stack>
      <Autocomplete
        disablePortal
        autoComplete
        id="client-search"
        onChange={handleOnChange}
        value={selectedClient}
        onInputChange={handleTyping}
        loadingText={t('loading')}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.text}
        renderOption={(props, option: Client) => (
          <li {...props} key={option.id}>
            {option.text}
          </li>
        )}
        selectOnFocus
        options={clients}
        sx={{ width: 200 }}
        size={'small'}
        renderInput={(params) => (
          <TextField
            {...(params as any)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={13} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            label={label ? label : defaultIsEnterprise ? t('enterprises') : t('individuals')}
          />
        )}
      />
      {(canSwitchEnterprise || canSwitchIndividual) && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {canSwitchIndividual && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isIndividual}
                    onChange={() => setIsIndividual(!isIndividual)}
                    name="isIndividual"
                  />
                }
                label={t('individuals')}
              />
            </FormGroup>
          )}
          {canSwitchEnterprise && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEnterprise}
                    onChange={() => setIsEnterprise(!isEnterprise)}
                    name="isEnterprise"
                  />
                }
                label={label ? label : defaultIsEnterprise ? t('enterprises') : t('individuals')}
              />
            </FormGroup>
          )}
        </Box>
      )}
    </Stack>
  )
}
