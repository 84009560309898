import { z } from 'zod'

const invoiceSchema = z.object({
  id: z.number(),
  status: z.number(),
  reference: z.string(),
  type: z.string(),
  contract: z.string(),
  contractId: z.number(),
  client: z.string(),
  clientId: z.number(),
  discr: z.string(),
  createdAt: z.string(),
  dueDate: z.string(),
  paidAt: z.string().nullable(),
  amount: z.number(),
  amountVat: z.number(),
  totalCost: z.number(),
  dueAmount: z.number(),
  paymentStatus: z.number(),
  statusLabel: z.string(),
  paymentStatusLabel: z.string(),
  paymentModeLabel: z.string()
})

export type Invoice = z.infer<typeof invoiceSchema>

const invoiceListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(invoiceSchema)
})

export type InvoiceList = z.infer<typeof invoiceListSchema>

const invoiceSumSchema = z.object({
  amount: z.number(),
  amountVat: z.number(),
  totalCost: z.number(),
  dueAmount: z.number()
})

export type InvoiceSum = z.infer<typeof invoiceSumSchema>

const InvoiceSchema = z.object({
  id: z.number(),
  reference: z.string(),
  contractType: z.number(),
  dueDate: z.string(), // Assurez-vous que le format de date est valide
  dueAmount: z.number(),
  state: z.number(),
  stateLabel: z.string(),
  isAccounted: z.boolean(),
  paymentLink: z.string(),
  paidAt: z.string().nullable(), // Peut être une chaîne ou nul
  clientName: z.string(),
  clientId: z.number(),
  accountedLabel: z.string(),
  languageId: z.number(),
  languageLabel: z.string()
})

const unLinkPaymentSchema = z.object({
  id: z.number(),
  mode: z.string(),
  amount: z.number(),
  period: z.string()
})

const paymentSchema = z.object({
  activePaymentsState: z.record(z.boolean()),
  isDebitDirect: z.boolean(),
  isPaymentMethod: z.boolean(),
  isGocardless: z.boolean(),
  isPendingSubmission: z.boolean()
})

const notificationSchema = z.object({
  id: z.number(),
  type: z.string(),
  created_at: z.string()
})

const InformationSchema = z.object({
  invoice: InvoiceSchema,
  partnerPaymentMethodId: z.number(),
  nextPossibleChargeDate: z.string(),
  unlinkPayments: z.array(unLinkPaymentSchema),
  minDueDate: z.string(),
  canApplyCredit: z.boolean(),
  isCreditAdd: z.boolean(),
  isInvoiceSheetEdit: z.boolean(),
  isInvoiceEdit: z.boolean(),
  isLitigationAdd: z.boolean(),
  isEditable: z.boolean(),
  isPayable: z.boolean(),
  isPaymentEdit: z.boolean(),
  isPaymentDirectDebit: z.boolean(),
  payments: paymentSchema,
  isCreditEdit: z.boolean(),
  isCreditable: z.boolean(),
  isInvoiceEditUncollectible: z.boolean(),
  isInvoiceUnPaid: z.boolean(),
  isInvoiceStateCollectible: z.boolean(),
  isInvoiceStateBlocked: z.boolean(),
  notifications: z.array(notificationSchema),
  activities: z.record(z.string())
})

export type InvoiceInformation = z.infer<typeof InformationSchema>

const PdfSchema = z.object({
  document_pdf: z.string()
})

export type InvoicePdf = z.infer<typeof PdfSchema>
