import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  type IdReturn,
  Opportunity,
  OpportunityContracts,
  OpportunityEvents,
  type OpportunityList,
  OpportunityQuotations,
  OpportunityUsers
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { OpportunityUpdateFormProps, QuotationCreateFormData } from 'api/models/forms/opportunities'

export const opportunitiesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<OpportunityList>({
      url: `${BASE_URL}${API.OPPORTUNITIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_OPPORTUNITIES_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      `opportunities_${new Date().toLocaleDateString()}.csv`
    ),
  getOne: async (id: number) =>
    fetcher<Opportunity>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY, { id })}`,
      method: 'GET',
      token: token
    }),
  getSolutions: async (id: number) =>
    fetcher<OpportunityQuotations>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY_SOLUTIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  getUsers: async (id: number) =>
    fetcher<OpportunityUsers>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY_USERS, { id })}`,
      method: 'GET',
      token: token
    }),
  getContracts: async (id: number) =>
    fetcher<OpportunityContracts>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getEvents: async (id: number, params: string) =>
    fetcher<OpportunityEvents>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY_EVENTS, { id })}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async (id: number, data: OpportunityUpdateFormProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createQuotation: async (opportunityId: number, data: QuotationCreateFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.OPPORTUNITY_QUOTATION_CREATE, { id: opportunityId })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  actualPipeline: async (params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.ACTUAL_PIPELINE}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
