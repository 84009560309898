import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const EmailTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  reference: z.string(),
  createdAt: z.string(),
  label: z.string()
})

const EmailTemplatesListSchema = itemListSchema(EmailTemplateSchema)

const EmailTemplateSheetSchema = z.object({
  id: z.number(),
  languageId: z.number(),
  lenguageLabel: z.string(),
  reference: z.string(),
  name: z.string(),
  html: z.string()
})

export type EmailTemplatesListType = z.infer<typeof EmailTemplatesListSchema>
export type EmailTemplateSheetType = z.infer<typeof EmailTemplateSheetSchema>
