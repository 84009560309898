import { useTranslation } from 'react-i18next'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { CustomerReservationInformations, CustomerReservationService } from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import { FormControl, Grid, MenuItem, Select } from '@mui/material'
import dayjs from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

export function DialogChangeService({
  customerReservation,
  dialogRef,
  service
}: {
  customerReservation: CustomerReservationInformations
  dialogRef: RefObject<DialogRef>
  service: CustomerReservationService | null
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const [services, setServices] = useState([])
  const [selectedService, setService] = useState('')
  const { getServiceAvailableDesktops, changeContractService } = useFetcher() //

  const fetchData = useCallback(async () => {
    if (!service) return
    await handleMutation({
      mutation: getServiceAvailableDesktops,
      data: {
        center: customerReservation.centerId,
        begin: dayjs(service.begin).format('YYYY-MM-DDTHH:mm:ss'),
        end: dayjs(service.end).format('YYYY-MM-DDTHH:mm:ss')
      },
      onSuccess: (data) => {
        setService(String(service.serviceId))
        setServices(data)
      }
    })
  }, [customerReservation, service])

  useEffect(() => {
    fetchData().then()
  }, [customerReservation, service])

  const handleSubmit = useCallback(async () => {
    if (!service || !selectedService) return
    await handleMutation({
      confirm: {
        content: t('confirm_change_service')
      },
      mutation: changeContractService,
      data: {
        id: String(customerReservation.contractId),
        contractServiceId: service.id,
        data: {
          contractServiceId: service.id,
          serviceId: Number(selectedService)
        }
      },
      toastSuccess: t('update_success'),
      toastError: t('update_error'),
      onSuccess: () => navigate(0)
    })
  }, [service, selectedService])

  return (
    <Dialog
      ref={dialogRef}
      title={`${t('change_service', { serviceLabel: service?.serviceLabel })}`}
      actions={
        <>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dialogRef.current?.close()
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={selectedService == ''}
            onClick={handleSubmit}
          >
            {t('submit')}
          </Button>
        </>
      }
    >
      <Grid container columns={12}>
        <FormControl sx={{ width: '100%', height: '100%' }} size={'small'}>
          <Select
            size={'small'}
            name="serviceId"
            onChange={(e) => setService(e.target.value)}
            value={selectedService}
          >
            {services.map((item: any) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    </Dialog>
  )
}
