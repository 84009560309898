import { Ctx, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import { FloorPlansList } from 'api/models/floor_plans_management'

export const floorPlansManagementRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<FloorPlansList>({
      url: `${BASE_URL}${API.FLOOR_PLANS_MANAGEMENT}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
