import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import Timeline, { RangeProps } from 'app/components/charts/timeline.component'
import { MultipleSelect } from 'app/components/filters/multiple-select'
import { PlanningDesktops } from 'api/models/plannings'
import dayjs from 'dayjs'
import { Filters } from 'api/models'
import { useApp } from 'app/providers/app.provider'

export const PlanningDesktopsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { filtersList, setOffset, setTotal, setIsLast, initFilters, handleFilter } = useList()
  const { setSearchParams, searchParams, getPlanningsDesktops, getPlanningsDesktopsFloors } =
    useFetcher()
  const { user, getRight } = useApp()
  const [planning, setPlanning] = useState<PlanningDesktops>()
  const [options, setOptions] = useState<Filters>({})
  const [ranges, setRanges] = useState<RangeProps>({} as RangeProps)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['planning_services_types', 'serviceType'],
      ['desktops_services_typologies', 'typologies']
    ])
  )

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    try {
      const planningReq = getPlanningsDesktops.mutateAsync()
      const optionsReq = getPlanningsDesktopsFloors.mutateAsync()
      const [data, options] = await Promise.all([planningReq, optionsReq])
      setPlanning(data)
      setOptions(options)
    } finally {
      setListIsLoading(false)
    }
  }, [setIsLast, setOffset, setTotal])

  useEffect(() => {
    setListIsLoading(true)
    if (!searchParams.get('center')) {
      searchParams.set('center', String(user?.mainCenter))
    }
    if (!searchParams.get('date_begin') && !searchParams.get('date_end')) {
      searchParams.set('date_begin', dayjs().startOf('year').format('YYYY-MM-DD'))
      searchParams.set('date_end', dayjs().endOf('year').format('YYYY-MM-DD'))
    }
    initFilters(commonFilters).then()
  }, [user])

  useEffect(() => {
    setRanges({
      start: dayjs(searchParams.get('date_begin')).startOf('day').toDate().getTime(),
      end: dayjs(searchParams.get('date_end')).endOf('day').toDate().getTime()
    })
  }, [searchParams])

  const series = useMemo(
    () => [
      {
        name: t('potential_renewal'),
        data: planning?.planning[4] ?? []
      },
      {
        name: t('awaiting_endorsement'),
        data: planning?.planning[5] ?? []
      },
      {
        name: t('without_commitment'),
        data: planning?.planning[1] ?? []
      },
      {
        name: t('booked'),
        data: planning?.planning[3] ?? []
      },
      {
        name: t('prebooked'),
        data: planning?.planning[2] ?? []
      }
    ],
    [planning, t]
  )

  const handleClickItem = useCallback((data: any) => {
    window.open(
      `/${data?.typeReference === 'CONSUMPTION' ? 'consumption-contracts' : 'contracts'}/${
        data.contractId
      }`,
      '_blank'
    )
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('services_planning')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="date"
            label={'reservation_date'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={getRight('select_center', 'isDisplay')}
            withDefaultValue
            disableClearable={true}
          />
          {options?.floors && (
            <MultipleSelect
              key={'floors'}
              filter={options?.floors}
              onChange={() => handleFilter(refreshList, true)}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          )}
        </FiltersBox>
      </Grid>
      <Grid textAlign={'center'}>
        {listIsLoading ? (
          <CircularProgress />
        ) : (
          <Timeline
            onItemClick={handleClickItem}
            categories={planning?.labels ? planning.labels : []}
            series={series}
            ranges={ranges}
          />
        )}
      </Grid>
    </Container>
  )
}
