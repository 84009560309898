import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Tooltip, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CenterGroupAccesCardItem } from 'modules/centers/components/center-group-acces-card-item-component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { AccessGroup, CenterDetails } from 'api/models'
import { AutocompleteSelect } from 'app/components/form/autocomplete-select'
import { useFeedback } from 'app/providers/feedback.provider'

interface ICenterGroupAccesCardProps {
  center: CenterDetails
}

export const CenterGroupAccesCard = ({ center }: ICenterGroupAccesCardProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { getCenterAccessGroups } = useFetcher()
  const { handleMutation } = useFeedback()
  const [accessGroups, setAccessGroups] = useState<AccessGroup[]>([])
  const [selectedGroup, setSelectedGroup] = useState<any>([])

  useEffect(() => {
    if (!center || !center.id) return
    handleMutation({
      mutation: getCenterAccessGroups,
      data: center.id,
      onSuccess: (data) => setAccessGroups(data)
    }).then()
  }, [center])

  return (
    <Grid container spacing={6} sx={{ marginBottom: theme.spacing(8) }} alignItems={'stretch'}>
      <Grid item xs={12} sm={12} md={3} lg={3} marginBottom={4} marginTop={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
          <AutocompleteSelect
            name={'name_group'}
            label={t('name_group')}
            valueSlug={'groupIndex'}
            labelSlug={'groupName'}
            items={accessGroups}
            value={selectedGroup}
            onChange={(data) => setSelectedGroup(data.map((d: AccessGroup) => d.groupIndex))}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} marginY={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={t('center_info_group_access')} arrow placement="left-start">
          <IconButton>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {accessGroups
          .filter(
            (accessGroup) =>
              selectedGroup?.length === 0 || selectedGroup.includes(accessGroup.groupIndex)
          )
          .map((accessGroup) => (
            <CenterGroupAccesCardItem
              key={accessGroup.groupIndex}
              accessGroup={accessGroup}
            ></CenterGroupAccesCardItem>
          ))}
      </Grid>
    </Grid>
  )
}
