import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQuery } from '@tanstack/react-query'
import { formatCurrency } from 'app/utils/format'
import { BorderedListComponent } from 'app/components/lists/bordered-list.component'
import { Box } from '@mui/material'

interface IProps {
  center: number
}

export const TableNomads = ({ center }: IProps) => {
  const { t } = useTranslation()
  const { Stats } = useFetcherV2()
  const { data = [], isFetching } = useQuery({
    queryKey: ['stats', 'nomad-occupation', 'clients', center],
    queryFn: () => Stats.nomadsOccupationByClients(center)
  })

  return (
    <>
      {data.length === 0 ? (
        <Box display="flex" justifyContent="center" height="100%">
          {t('error_empty_contract')}
        </Box>
      ) : (
        <BorderedListComponent
          columns={[
            {
              key: 'clientName',
              label: t('client'),
              link: '/enterprises/:clientId'
            },
            {
              key: 'count',
              label: t('number_of_recurrent_contract_nomad')
            },
            {
              key: 'totalPrice',
              label: t('total'),
              valueFormatter: (data) => formatCurrency(data.totalPrice)
            },
            {
              key: 'avgPrice',
              label: t('avg'),
              valueFormatter: (data) => formatCurrency(data.avgPrice)
            }
          ]}
          data={data}
          keyBy={'clientId'}
          isLoading={isFetching}
        />
      )}
    </>
  )
}
