import { Box, Card, CardContent, Link } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useState } from 'react'
import { Opportunity, OpportunityQuotations } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { formatCurrency, formatDate, formatSurface } from 'app/utils/format'
import { DeepListComponent } from 'app/components/lists/deep-list.component'

interface IServicesProps {
  data: Opportunity
}

export const SolutionsComponent = ({ data }: IServicesProps) => {
  const [quotations, setQuotations] = useState<OpportunityQuotations>([] as OpportunityQuotations)
  const { getOpportunitySolutions } = useFetcher()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    if (!data.id) return
    setIsLoading(true)
    await getOpportunitySolutions
      .mutateAsync(data.id)
      .then((data) => setQuotations(data))
      .finally(() => setIsLoading(false))
  }, [data])

  useEffect(() => {
    fetch().then()
  }, [fetch])

  return (
    <Card>
      <CardContent>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <TitleComponent text={t('solutions')} variant={'h3'} paddingLeft={12} />
        </Box>
        <DeepListComponent
          isLoading={isLoading}
          headers={[t('offices'), t('surface'), t('price'), t('commitment')]}
          rows={[
            {
              columns: [
                {
                  slug: 'id',
                  content: (item: any, slug: string) => (
                    <Link href={`/quotations/${item.id}`}>
                      {t('quotation')} : {item[slug]}
                    </Link>
                  )
                },
                {
                  slug: 'dueDate',
                  colSpan: 3,
                  format: formatDate
                }
              ],
              children: {
                slug: 'solutions',
                columns: [
                  {
                    slug: 'services',
                    content: (item: any, slug: string) => {
                      return item[slug].map((i: any) => i.serviceLabel).join(', ')
                    }
                  },
                  {
                    slug: 'surface',
                    format: formatSurface
                  },
                  {
                    slug: 'price',
                    format: formatCurrency
                  },
                  {
                    slug: 'commitmentLabel'
                  }
                ]
              }
            }
          ]}
          data={quotations}
        />
      </CardContent>
    </Card>
  )
}
