import { useTranslation } from 'react-i18next'
import { Grid, Paper as MuiPaper } from '@mui/material'
import type { ClientLink, ClientLinkList, FormItems, IndividualDetails } from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { FormList } from 'app/components/form/form-list.component'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { useNavigate } from 'react-router-dom'

interface IIndividualEnterprises {
  individual: IndividualDetails
  options: FormItems
}

export function IndividualEnterprisesComponent({ individual, options }: IIndividualEnterprises) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const methods = useForm({
    values: { items: [] as ClientLinkList }
  })
  const { getIndividualEnterprises, updateClientLink } = useFetcher()
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getIndividualEnterprises.mutateAsync(individual.id)
      methods.setValue('items', data)
    } finally {
      setIsLoading(false)
    }
  }, [individual.id])

  const handleSave = useCallback(async (data: ClientLink) => {
    await handleMutation({
      confirm: {
        content: t('confirm_update_client_link')
      },
      mutation: updateClientLink,
      data: {
        id: data.clientLinkId,
        individualId: individual.id,
        data: data
      },
      onSuccess: () => navigate(0),
      toastSuccess: t('update_success'),
      toastError: t('an_error_occurred')
    })
  }, [])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('enterprises')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <FormList
            items={methods.getValues().items}
            rows={[
              {
                columns: [
                  {
                    type: 'checkbox',
                    label: t('active'),
                    slug: 'isActive'
                  },
                  {
                    type: 'text',
                    label: t('enterprise_name'),
                    slug: 'enterpriseName',
                    link: { base: '/enterprises', slug: 'enterpriseId' }
                  },
                  {
                    type: 'select',
                    label: t('role'),
                    slug: 'role',
                    formItem: options.individual_roles
                  },
                  {
                    type: 'textfield',
                    label: t('job'),
                    slug: 'job'
                  },
                  {
                    type: 'checkbox',
                    label: t('accounting_mail_recipient'),
                    slug: 'isAccountingMailRecipient'
                  },
                  {
                    type: 'checkbox',
                    label: t('main_enterprise'),
                    slug: 'isMainEnterprise'
                  },
                  {
                    type: 'button',
                    label: '',
                    buttonLabel: t('submit'),
                    onClick: handleSave
                  }
                ]
              }
            ]}
            methods={methods}
            isLoading={isLoading}
            itemsName={'items'}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
