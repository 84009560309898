import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ListAccordion } from 'app/components/lists/list-accordion'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import {
  Notification,
  Notifications,
  NotificationList,
  NotificationUpdate,
  NotificationsSchema
} from 'api/models/notifications'
import { Edit } from '@mui/icons-material'
import { NotificationsSelect } from 'app/components/filters/notifications-select'
import { ListPagination } from 'app/components/lists/list-pagination'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { NotificationStateSwitch } from 'modules/notifications/components/notification-state-switch.component'
import { NotificationEditDialog } from 'modules/notifications/components/notification-edit-dialog.component'
import { NotificationRecipients } from 'modules/notifications/components/notification-recipients.component'

export function NotificationsView() {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getNotifications, searchParams, setSearchParams } = useFetcher()
  const [notifications, setNotifications] = useState<Notifications>([])
  const { handleMutation } = useFeedback()
  const [loading, setLoading] = useState<boolean>(false)
  const dialogRef = useRef<DialogRef>(null)
  const [formData, setFormData] = useState<NotificationUpdate | null>({})
  const [currentId, setCurrentId] = useState<number | null>(null)

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setLoading(true),
      mutation: getNotifications,
      onSuccess: (data: NotificationList) => {
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        const parsedData = NotificationsSchema.parse(data.items)
        setNotifications(parsedData)
      },
      onEnd: () => setLoading(false)
    })
  }, [setIsLast, setOffset, setTotal, setSearchParams])

  const handleOpenDialog = (item: Notification) => {
    setCurrentId(item.id)
    setFormData({
      label: item.label,
      notificationStaff: item.notificationStaff.id.map((id: number, index: number) => ({
        id: id,
        names: item.notificationStaff.names[index]
      })),
      notificationRole: item.notificationRole.id.map((id: number, index: number) => ({
        id: id,
        label: item.notificationRole.label[index]
      })),
      additionalRecipients: item.additionalRecipients,
      isCenterRecipient: item.isCenterRecipient,
      description: item.description
    })
    dialogRef.current?.open()
  }

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('notifications_rules')}
        </Typography>
      </Box>
      <NotificationsSelect
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onChange={refreshList}
      />
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {notifications.length} / {total}
          </Typography>
        </Grid>
        <ListAccordion
          items={notifications}
          columns={[
            {
              label: t('notification'),
              slug: 'label'
            },
            {
              label: t('default_recipient'),
              slug: 'autoRecipient'
            },
            {
              label: t('additional_recipients'),
              slug: 'isCenterRecipient',
              condition: (item: Notification) => <NotificationRecipients notification={item} />
            },
            {
              label: t('state'),
              slug: 'isActive',
              condition: (item: Notification) => (
                <NotificationStateSwitch item={item} refreshList={refreshList} />
              )
            },
            {
              label: '',
              slug: 'id',
              condition: (item: Notification) => (
                <IconButton
                  disabled={!item.isEditable}
                  onClick={() => handleOpenDialog(item)}
                  size="small"
                  color="primary"
                >
                  <Edit fontSize="small" />
                </IconButton>
              )
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={loading}
          contentKey={'description'}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {notifications.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !loading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
      <NotificationEditDialog
        dialogRef={dialogRef}
        formData={formData}
        setFormData={setFormData}
        refreshList={refreshList}
        currentId={currentId}
        readOnlyData={notifications.find((item) => item.id === currentId)}
      />
    </Container>
  )
}
