import { z } from 'zod'

const consumptionSchema = z.object({
  id: z.number(),
  label: z.string(),
  price: z.number(),
  commissioningFees: z.number(),
  isFreePrice: z.boolean(),
  isFreeFees: z.boolean(),
  hourlyPrice: z.number().nullable(),
  halfdayPrice: z.number().nullable(),
  dailyPrice: z.number().nullable()
})

const consumptionsListSchema = z.array(consumptionSchema)

const consumptionListParams = z.object({
  center: z.number(),
  serviceTypeReference: z.string(),
  clientId: z.number()
})

export type Consumption = z.infer<typeof consumptionSchema>
export type ConsumptionsList = z.infer<typeof consumptionsListSchema>
export type ConsumptionsListParams = z.infer<typeof consumptionListParams>
