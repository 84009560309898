import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { ClientRequestInformation, FormItems } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { ClientRequestDetails } from 'modules/clientServices/components/client_request-details.component'
import { ArrowBack } from '@mui/icons-material'

export const ClientRequestView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems } = useFetcher()
  const { id } = useParams()
  const [clientRequest, setClientRequest] = useState<ClientRequestInformation>(
    {} as ClientRequestInformation
  )
  const { getClientRequest } = useFetcher()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['clientrequest_subcategory', 'subcategory'],
      ['clientrequest_status', 'status']
    ])
  )

  const refreshList = useCallback(async () => {
    if (!id) return
    try {
      setListIsLoading(true)
      const data = await getClientRequest.mutateAsync(id ? Number(id) : 0)
      setClientRequest(data)
    } finally {
      setListIsLoading(false)
    }
  }, [id])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
    })
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then(() => refreshList())
  }, [])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              <Link to={`/clientrequests`} style={{ verticalAlign: 'middle' }}>
                <ArrowBack />
              </Link>
              {t('client_request')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <ClientRequestDetails clientRequest={clientRequest} options={options} />
    </Container>
  )
}
