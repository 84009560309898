import { z } from 'zod'
import { itemListSchema } from './commons'

const commitmentSchema = z.object({
  id: z.number(),
  label: z.string(),
  reference: z.string()
})

const commitmentsSchema = itemListSchema(commitmentSchema)
export type CommitmentsList = z.infer<typeof commitmentsSchema>
export type Commitment = z.infer<typeof commitmentSchema>
