import { z } from 'zod'
import dayjs from 'dayjs'

export const cancelContractSchema = z.object({
  end: z.string(),
  state: z.number()
})

export type CancelContractProps = z.infer<typeof cancelContractSchema>

export const updateAdminContractSchema = z.object({
  deadline: z.instanceof(dayjs as any).nullable(),
  end: z.instanceof(dayjs as any).nullable(),
  staff: z.number(),
  invoicing: z.string()
})

export type UpdateAdminContractProps = z.infer<typeof updateAdminContractSchema>
