import React, { useCallback, useMemo, useState } from 'react'

import { Box, Grid, Typography, useTheme } from '@mui/material'
import { Container, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { TitleComponent } from 'app/components/titles/title.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { DashboardCardContract } from 'modules/dashboard/components/dashboardcard-contract'
import { DashboardCardOccupancy } from 'modules/dashboard/components/dashboardcard-occupancy'
import { DashboardCardVisits } from 'modules/dashboard/components/dashboardcard-visits'
import { DashboardCardNextEntries } from 'modules/dashboard/components/dashboardcard-nextentries'
import { DashboardCardMeetings } from 'modules/dashboard/components/dashboardcard-meetings'
import { DashboardCardOpenDesktops } from 'modules/dashboard/components/dashboardcard-opendesktops'
import { DashboardListOtherInformation } from 'modules/dashboard/components/dashboardlist-otherinformation'
import {
  DashboardContract,
  DashboardMeetings,
  DashboardNextEntries,
  DashboardOccupations,
  DashboardOpenDesktops,
  DashboardOtherInformation,
  DashboardVisits
} from 'api/models/dashboard'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

const DashboardView = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { searchParams, setSearchParams } = useFetcher()
  const [center, setCenter] = useState('')
  const { handleFilter } = useList()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['staff_centers', 'center']])
  )

  const { Dashboard } = useFetcherV2()
  const { data, isFetching: isLoading } = useQueryFetcher({
    queryKey: ['stats', 'dashboard', searchParams.toString()],
    queryFn: async () => {
      const params = searchParams.toString()
      return await Promise.all([
        Dashboard.getContracts(params),
        Dashboard.getMeetings(params),
        Dashboard.getNexEntries(params),
        Dashboard.getVisits(params),
        Dashboard.getOpenDesktops(params),
        Dashboard.getOtherInformation(params),
        Dashboard.getOccupations(params)
      ])
    }
  })

  const [
    contracts = {} as DashboardContract,
    meetings = [] as DashboardMeetings,
    nextEntries = [] as DashboardNextEntries,
    visits = [] as DashboardVisits,
    openDesktops = [] as DashboardOpenDesktops,
    otherInformations = {} as DashboardOtherInformation,
    occupations = [] as DashboardOccupations
  ] = useMemo(() => {
    return data ?? []
  }, [data])

  const refreshList = useCallback(async () => {
    const param = String(new URLSearchParams(searchParams).get('center'))
    setCenter(param)
  }, [searchParams])

  return (
    <Container data-cy="dashboard-component">
      <Box marginBottom="2rem">
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h2" gutterBottom display="inline">
            {t('dashboard')}
          </Typography>
          <Stack>
            <FiltersContainer
              commonFilters={commonFilters}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              onChange={() => handleFilter(refreshList, true)}
            ></FiltersContainer>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Grid
            container
            spacing={6}
            sx={{ marginBottom: theme.spacing(8), minHeight: 170 }}
            alignItems={'stretch'}
          >
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DashboardCardContract contracts={contracts} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={8}>
              <DashboardCardOccupancy
                occupations={occupations}
                center={center}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={6}
            sx={{ marginBottom: theme.spacing(8) }}
            alignItems={'stretch'}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TitleComponent
                text={t('next_visit')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={6}
              />
              <DashboardCardVisits visits={visits} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TitleComponent
                text={t('next_entries')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={6}
              />
              <DashboardCardNextEntries nextEntries={nextEntries} isLoading={isLoading} />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ marginBottom: theme.spacing(4) }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TitleComponent
                text={t('occupancy_of_meeting_rooms')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={6}
              />
              <DashboardCardMeetings meetings={meetings} isLoading={isLoading} />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TitleComponent
                text={t('occupancy_of_transit_offices')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={6}
              />
              <DashboardCardOpenDesktops openDesktops={openDesktops} isLoading={isLoading} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardListOtherInformation
            otherInformations={otherInformations}
            center={center}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default DashboardView
