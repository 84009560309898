import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Typography, Link as MuiLink, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import {
  QuotationInformation,
  QuotationService,
  QuotationSolution,
  QuotationSolutions
} from 'api/models'
import {
  formatCurrency,
  formatReductionOrPrice,
  formatDate,
  formatNumbers,
  formatPercentage,
  formatSurface
} from 'app/utils/format'
import dayjs from 'dayjs'
import { CircleRounded, EmojiPeople, QuestionMark, SquareFoot, ThumbUp } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { SolutionEditDialog } from 'modules/quotations/components/solution-edit-dialog.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'

interface IQuotationDetailsProps {
  quotation: QuotationInformation
  children?: React.ReactNode
}

export const QuotationSolutionsList = ({ quotation }: IQuotationDetailsProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [selectedSolution, setSelectedSolution] = useState<QuotationSolution>(
    {} as QuotationSolution
  )
  const { getQuotationSolutions, transformQuotationSolution } = useFetcher()
  const [solutions, setSolutions] = useState([] as QuotationSolutions)
  const { orderBy, handleSort, handleFilter } = useList()
  const { user } = useApp()
  const solutionDialogRef = useRef<DialogRef>(null)
  const refreshList = useCallback(async () => {
    if (!quotation.id) return
    setListIsLoading(true)
    const data = await getQuotationSolutions.mutateAsync(quotation.id)
    setListIsLoading(false)
    setSolutions(data)
  }, [quotation.id, getQuotationSolutions, setListIsLoading, setSolutions])

  const transformSolution = async (solutionId: number) => {
    await handleMutation({
      confirm: {
        content: t('confirm_transform_solution')
      },
      mutation: transformQuotationSolution,
      data: { id: quotation.id, solutionId: solutionId },
      toastSuccess: t('quotation_solution_transformed'),
      toastError: 'an_error_occurred',
      onSuccess: () => {
        refreshList().then()
      }
    })
  }

  const formatActions = useCallback(
    (item: any) => {
      return user?.rights.contract?.isAdd &&
        Number(item.status) <= 2 &&
        item.isTransformable &&
        !dayjs().isAfter(quotation.dueDate, 'days') ? (
        <IconButton
          aria-label={t('transform_to_contract')}
          onClick={() => transformSolution(item.id)}
        >
          <ThumbUp color="primary" />
        </IconButton>
      ) : (
        <Tooltip
          title={
            dayjs().isAfter(quotation.dueDate, 'days')
              ? t('quotation_deadline_exceeded')
              : t('quotation_busy_office')
          }
        >
          <QuestionMark />
        </Tooltip>
      )
    },
    [quotation, dayjs, transformSolution]
  )

  const editSolution = useCallback(
    (id: number) => {
      const solution = solutions.find((s) => s.id === id)
      if (!solution) return
      setSelectedSolution(solution)
      solutionDialogRef.current?.open()
    },
    [solutions]
  )

  const formatQuotationId = (id: number, state: number) => (
    <>
      <CircleRounded
        style={{ verticalAlign: 'middle', marginRight: '0.5rem' }}
        fontSize={'small'}
        color={
          state === 3 ? 'success' : state === 2 ? 'warning' : state === 4 ? 'error' : 'primary'
        }
      />
      {user?.rights.quotation.isEdit && state < 3 ? (
        <MuiLink onClick={() => editSolution(id)}>{id}</MuiLink>
      ) : (
        id
      )}
    </>
  )

  const formatCapacityAndSurface = (capacity: number, surface: number) => (
    <>
      <EmojiPeople color={'primary'} style={{ verticalAlign: 'middle' }} fontSize={'small'} />{' '}
      {formatNumbers(capacity)}
      <SquareFoot
        color={'primary'}
        style={{ verticalAlign: 'middle', marginLeft: '0.5rem' }}
        fontSize={'small'}
      />{' '}
      {formatSurface(surface)}
    </>
  )

  const formatQuotationServices = (data: QuotationService[]) =>
    data.map((d) => (
      <Typography key={d.serviceId} color={!d.isAvailable ? 'error' : undefined}>
        {d.serviceLabel}
      </Typography>
    ))

  useEffect(() => {
    refreshList().then()
  }, [])

  return (
    <>
      <SolutionEditDialog
        dialogRef={solutionDialogRef}
        quotation={quotation}
        solution={selectedSolution}
      />
      <Box marginBottom={4} marginTop={4} paddingLeft={4}>
        <TitleComponent text={t('solutions')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={solutions}
            selectable={false}
            columns={[
              {
                slug: 'id',
                label: t('id'),
                text: 'status',
                valueFormatter: formatQuotationId
              },
              {
                slug: 'services',
                label: t('offices_and_services'),
                valueFormatter: formatQuotationServices
              },
              {
                slug: 'capacity',
                text: 'surface',
                label: t('properties'),
                valueFormatter: formatCapacityAndSurface
              },
              {
                slug: 'commitmentLabel',
                label: t('commitment')
              },
              {
                slug: 'begin',
                label: t('begin'),
                valueFormatter: formatDate
              },
              {
                slug: 'deadline',
                label: t('end'),
                valueFormatter: formatDate
              },
              {
                slug: 'price',
                label: t('price'),
                valueFormatter: formatCurrency
              },
              {
                slug: 'reduction',
                label: t('reduction'),
                valueFormatter: formatPercentage
              },
              {
                slug: 'reductionPrice',
                text: 'price',
                label: t('reduction_price'),
                condition: formatReductionOrPrice,
                valueFormatter: formatCurrency
              },
              {
                slug: 'staffLabelledName',
                label: t('member')
              },
              {
                slug: 'status',
                label: '',
                condition: formatActions
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={listIsLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}
