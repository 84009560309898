import { z } from 'zod'

const discountGridsCenterSchema = z.object({
  id: z.number(),
  centerId: z.number(),
  centerName: z.string(),
  discountGridId: z.number(),
  discountGridLabel: z.string(),
  discountGridType: z.string(),
  discountGridTypeLabel: z.string(),
  begin: z.string(),
  end: z.string().nullable(),
  inProgress: z.string()
})

export type DiscountGridsCenter = z.infer<typeof discountGridsCenterSchema>

const discountGridsCenterListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(discountGridsCenterSchema)
})

export type DiscountGridsCenterList = z.infer<typeof discountGridsCenterListSchema>

const discountGridsCenterBodySchema = z.object({
  center: z.number(),
  discountGrid: z.number(),
  oldDiscountGrid: z.number(),
  begin: z.string()
})

export type DiscountGridsCenterBody = z.infer<typeof discountGridsCenterBodySchema>

const discountGridsCenterResponseSchema = z.object({
  centerDiscountGrid: z.number(),
  oldCenterDiscountGrid: z.number()
})

export type DiscountGridsCenterResponse = z.infer<typeof discountGridsCenterResponseSchema>
