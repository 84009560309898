import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { CenterDetails, CenterUpdateProps } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import { FormCard } from 'app/components/form/form-card.component'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ZodEffects, ZodObject } from 'zod'
import dayjs from 'dayjs'

interface ICenterUpdateContainerProps {
  title: string
  items: FormInputProps[]
  resolver: ZodEffects<any, any, any> | ZodObject<any>
}

export const CenterUpdateContainer = function ({
  title,
  items,
  resolver
}: ICenterUpdateContainerProps): React.JSX.Element {
  const { t } = useTranslation()
  const { id } = useParams()
  const { getCenter, updateCenter } = useFetcher()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [center, setCenter] = useState<CenterDetails>({} as CenterDetails)
  const methods = useForm<CenterUpdateProps>({
    defaultValues: {
      centerOpeningDays: []
    },
    mode: 'onChange',
    resolver: zodResolver(resolver)
  })
  const { isValid } = methods.formState

  useEffect(() => {
    handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getCenter,
      data: id ? Number(id) : 0,
      onSuccess: (data) => {
        setCenter(data)
        methods.reset({
          ...data,
          centerOpeningDays: data.centerOpeningDays
            ? data.centerOpeningDays.map((days) => days.openingDay)
            : [],
          leaseSigningDate: data.leaseSigningDate ? dayjs.utc(data.leaseSigningDate) : undefined,
          madaDate: data.madaDate ? dayjs.utc(data.madaDate) : undefined,
          openingHour: data.openingHour ? dayjs.utc(data.openingHour) : undefined,
          closureHour: data.closureHour ? dayjs.utc(data.closureHour) : undefined,
          openingDate: data.openingDate ? dayjs.utc(data.openingDate) : undefined
        })
      },
      onEnd: () => setIsLoading(false)
    }).then()
  }, [])

  const handleSubmit = useCallback(
    async (data: CenterUpdateProps) => {
      if (!center) return
      await handleMutation({
        confirm: {
          content: t('confirm_edit_center')
        },
        mutation: updateCenter,
        data: {
          id: Number(center?.id),
          data: data as CenterDetails
        },
        onSuccess: () => navigate(`/centers/${center.id}`)
      })
    },
    [center]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('center_sheet')} | {center?.name}{' '}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">{center?.reference}</Typography>
      <Box marginTop={7}>
        <FormCard isLoading={isLoading} title={t(title)} control={methods.control} items={items}>
          <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!isValid}>
            {t('save')}
          </Button>
        </FormCard>
      </Box>
    </Container>
  )
}
