import { z } from 'zod'
import dayjs from 'dayjs'

const consumptionItemSchema = z.object({
  commissioningFees: z.number(),
  dailyPrice: z.number().nullable(),
  halfdayPrice: z.number().nullable(),
  hourlyPrice: z.number().nullable(),
  id: z.number(),
  isFreeFees: z.union([z.number().transform((e) => e === 1), z.boolean()]),
  isFreePrice: z.union([z.number().transform((e) => e === 1), z.boolean()]),
  label: z.string(),
  price: z.number()
})

export type ConsumptionItem = z.infer<typeof consumptionItemSchema>

const consumptionItemsForm = z
  .object({
    isValid: z.boolean().optional(),
    service: z.number(),
    label: z.string(),
    customlabel: z.string().optional(),
    customprice: z.coerce.number(),
    quantity: z.coerce.number().min(1),
    begin: z.instanceof(dayjs as any),
    end: z.instanceof(dayjs as any).nullable(),
    reduction: z.coerce.number().min(0),
    price: z.number(),
    fees: z.coerce.number().min(0).nullable(),
    isFreePrice: z.union([z.number().transform((e) => e === 1), z.boolean()]),
    isFreeFees: z.union([z.number().transform((e) => e === 1), z.boolean()]),
    serviceType: z.string(),
    customcomment: z.string().optional(),
    customisrecurent: z.number().optional(),
    consumption: z.any()
  })
  .refine((field) => {
    return (
      field.begin &&
      (!field.end || field.begin.isBefore(field.end)) &&
      (!field.reduction || field.reduction <= 100)
    )
  })

export type ConsumptionItemForm = z.infer<typeof consumptionItemsForm>

export const formSchema = z.object({
  contractId: z.number({ coerce: true }),
  center: z.number({ coerce: true }),
  owner: z.number({ coerce: true }),
  client: z.number({ coerce: true }),
  items: z.array(consumptionItemsForm)
})

export type FormProps = z.infer<typeof formSchema>

export const addReservationConsumption = z.object({
  reservationId: z.number(),
  service: z.number({ coerce: true }),
  quantity: z.number({ coerce: true }).min(1),
  price: z.number({ coerce: true }).min(0),
  begin: z.instanceof(dayjs as any),
  end: z.instanceof(dayjs as any),
  reduction: z.number({ coerce: true }).optional(),
  fees: z.number({ coerce: true }).optional(),
  isFreePrice: z.union([z.number().transform((e) => e === 1), z.boolean()]).optional(),
  isFreeFees: z.union([z.number().transform((e) => e === 1), z.boolean()]).optional()
})

export type AddReservationConsumptionItems = z.infer<typeof addReservationConsumption>
