import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'
import dayjs, { Dayjs } from 'dayjs'

const discountItemSchema = z.object({
  id: z.number(),
  label: z.string(),
  begin: z.string(),
  end: z.string(),
  envelope: z.string(),
  status: z.number()
})

const discountListSchema = itemListSchema(discountItemSchema)

export type DiscountItem = z.infer<typeof discountItemSchema>
export type DiscountsList = z.infer<typeof discountListSchema>

const centerDiscountSchema = z.object({
  centerName: z.string(),
  begin: z.string(),
  end: z.string().nullable()
})

const discountRatesByRoleSchema = z.record(
  z.string(),
  z.array(
    z.object({
      roleName: z.string(),
      commitmentLabel: z.string(),
      rate: z.number()
    })
  )
)

const discountContractSchema = z.object({
  id: z.number(),
  reference: z.string(),
  clientName: z.string(),
  commitmentLabel: z.string(),
  reductionValue: z.number()
})

const discountQuotationSchema = z.object({
  id: z.number(),
  clientName: z.string(),
  opportunityId: z.number(),
  opportunityReference: z.string(),
  maxReductionValue: z.number()
})

const discountSchema = z.object({
  id: z.number(),
  label: z.string(),
  begin: z.string(),
  end: z.string().nullable(),
  envelop: z.number(),
  isAClassicDiscount: z.boolean(),
  totalReductionValue: z.number(),
  remainingReductionValue: z.number(),
  totalContracts: z.number(),
  totalQuotations: z.number(),
  centerDiscounts: z.array(centerDiscountSchema),
  discountRatesByRole: discountRatesByRoleSchema,
  contracts: z.array(discountContractSchema),
  quotations: z.array(discountQuotationSchema)
})

export type DiscountDetails = z.infer<typeof discountSchema>

const rolesSchema = z.object({
  admin: z.number({ coerce: true }).min(0).max(100),
  siteDirector: z.number({ coerce: true }).min(0).max(100),
  rxp: z.number({ coerce: true }).min(0).max(100),
  direction: z.number({ coerce: true }).min(0).max(100),
  commercial: z.number({ coerce: true }).min(0).max(100),
  tls: z.number({ coerce: true }).min(0).max(100)
})

export const addDiscountSchema = z.object({
  label: z.string(),
  begin: z
    .instanceof(dayjs as unknown as typeof Dayjs)
    .transform((date) => date.format('YYYY-MM-DD')),
  roles: z.array(rolesSchema)
})

export type DiscountAddBody = z.infer<typeof addDiscountSchema>

const existDiscountSchema = z.object({
  found: z.boolean()
})

export type DiscountExist = z.infer<typeof existDiscountSchema>
