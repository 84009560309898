import styled from '@emotion/styled'
import { AccountCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Link as ActionLink, Paper as MuiPaper } from '@mui/material'
import { Box, Stack } from '@mui/system'
import type { FormItems, IndividualDetails } from 'api/models'
import { Link } from 'app/components/link.component'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { List } from 'app/components/lists/list'
import {
  formatBeginWithLate,
  formatDateWithTime,
  formatEventStatus,
  formatStaffBadge
} from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import type { Event } from 'api/models'
import { TitleComponent } from 'app/components/titles/title.component'
import { InformationCardComponent } from 'modules/clients/components/information-card.component'
import { WifiCardComponent } from 'modules/clients/components/wifi-card.component'
import { useApp } from 'app/providers/app.provider'
import { ModulesEnum } from 'app/constants/modules'
import { PartnerCardComponent } from './partner-card.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { EditEventDialog } from 'modules/events/views/edit-event-dialog.component'

const BoxInline = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 1rem;
`

const LinkGrid = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 1rem;
`

interface IIndividualDetailsProps {
  individual: IndividualDetails
  options: FormItems
}

export const IndividualDetailsCard = ({ individual, options }: IIndividualDetailsProps) => {
  const { t } = useTranslation()
  const { getModule } = useApp()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { getIndividualEvents } = useFetcher()
  const [hasWifiModule, setHasWifiModule] = useState<boolean>(false)
  const [hasPartnersModule, setHasPartnersModule] = useState<boolean>(false)
  const [events, setEvents] = useState([] as Event[])
  const { orderBy, handleSort, handleFilter } = useList()
  const dialogRef = useRef<DialogRef>(null)
  const [selectedEvent, setSelectedEvent] = useState<Event>({} as Event)

  const refreshList = useCallback(async () => {
    if (!individual.id) return
    setListIsLoading(true)
    const data = await getIndividualEvents.mutateAsync(individual.id)
    setListIsLoading(false)
    setEvents(data.items)
  }, [individual.id])

  useEffect(() => {
    refreshList().then()
  }, [individual.id])

  useEffect(() => {
    const _wifiModule = getModule(ModulesEnum.WIFI)
    if (undefined !== _wifiModule) {
      setHasWifiModule(true)
    }

    const _partnersModule = getModule(ModulesEnum.PARTNERS)
    if (undefined !== _partnersModule) {
      setHasPartnersModule(true)
    }
  }, [])

  return (
    <>
      <BoxInline>
        <MuiPaper style={{ flex: 1 }}>
          <InformationCardComponent individual={individual} />
        </MuiPaper>
        {hasWifiModule && (
          <MuiPaper style={{ flex: 1 }}>
            <WifiCardComponent individual={individual} />
          </MuiPaper>
        )}
        {hasPartnersModule && (
          <MuiPaper style={{ flex: 1 }}>
            <PartnerCardComponent individual={individual} />
          </MuiPaper>
        )}
      </BoxInline>
      <MuiPaper>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <TitleComponent
              text={t('accounting_documents')}
              variant={'h3'}
              paddingTop={0}
              paddingLeft={12}
            />
          </Stack>
        </Box>
        <LinkGrid>
          <Link to={`/invoices?is_mandate=0&client=${individual.id}&status=%5B%5D&offset=0`}>
            {t('invoices')}
          </Link>
          <Link to={`/messages?type=0&client=${individual.id}&offset=0`}>{t('messages')}</Link>
        </LinkGrid>
        <Divider />
        <Box marginBottom={4} marginTop={4} paddingLeft={4}>
          <TitleComponent text={t('events')} variant={'h3'} paddingTop={0} paddingLeft={12} />
        </Box>
        <Grid container columns={4}>
          <Grid item xs={4}>
            <List
              items={events}
              selectable={false}
              columns={[
                {
                  slug: 'statusId',
                  label: '',
                  text: 'statusLabel',
                  valueFormatter: formatEventStatus
                },
                {
                  slug: 'typeLabel',
                  label: t('type'),
                  condition: (item: Event) => (
                    <ActionLink
                      onClick={() => {
                        setSelectedEvent(item)
                        dialogRef.current?.open()
                      }}
                    >
                      {item.typeLabel}
                    </ActionLink>
                  )
                },
                { slug: 'centerName', label: t('center') },
                {
                  slug: 'late',
                  label: t('begin_at'),
                  condition: (row: Event) =>
                    formatBeginWithLate(row.late, row.beginLabel, row.statusId)
                },
                {
                  slug: 'endLabel',
                  label: t('end_at'),
                  valueFormatter: formatDateWithTime
                },
                {
                  slug: 'staffInitial',
                  label: AccountCircle,
                  valueFormatter: formatStaffBadge,
                  tooltip: t('staff')
                }
              ]}
              handleReset={() => handleFilter(refreshList, true)}
              sort={orderBy}
              handleSort={(property) => handleSort(refreshList, property)}
              isLoading={listIsLoading}
            />
          </Grid>
        </Grid>
        <EditEventDialog
          options={options}
          dialogRef={dialogRef}
          selectedEvent={selectedEvent}
          onSuccess={() => {
            setSelectedEvent({} as Event)
            refreshList().then()
            dialogRef.current?.close()
          }}
        />
      </MuiPaper>
    </>
  )
}
