import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent, FormControl, Grid, MenuItem, Select, Stack } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { useTranslation } from 'react-i18next'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import type { EnterpriseInformation } from 'api/models'

export interface IProps {
  enterprise: EnterpriseInformation
  ssids: any
  isLoading: boolean
}

export const EnterpriseSsidUsersComponent = ({ enterprise, ssids, isLoading }: IProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { updateEnterpriseSsidUser, createEnterpriseSsidUser, getEnterpriseSsidsUsers } =
    useFetcher()

  const [ssidUsers, setSsidUsers] = useState<any>([])
  const [selectedSsid, setSelectedSsid] = useState<any>(null)
  const [isUsersLoading, setIsUsersLoading] = useState(false)

  const fetchUsers = useCallback(async () => {
    if (!selectedSsid) return
    setIsUsersLoading(true)
    const _users = await getEnterpriseSsidsUsers.mutateAsync({
      id: enterprise.id,
      ssid: selectedSsid
    })
    setSsidUsers(_users)
    setIsUsersLoading(false)
  }, [enterprise, selectedSsid])

  const handleUpdateUser = useCallback(
    async (user: any, e: ChangeEvent<HTMLInputElement>) => {
      if (!user.ssidUserId) {
        await handleMutation({
          confirm: {
            content: t('confirm_add_ssid_user')
          },
          mutation: createEnterpriseSsidUser,
          onEnd: fetchUsers,
          data: {
            id: enterprise.id,
            ssid: selectedSsid,
            data: {
              individualId: user.individualId
            }
          }
        })
      } else {
        await handleMutation({
          confirm: {
            content: t('confirm_update_ssid_user')
          },
          mutation: updateEnterpriseSsidUser,
          onEnd: fetchUsers,
          data: {
            id: enterprise.id,
            ssid: selectedSsid,
            ssidUser: user.ssidUserId,
            data: {
              individualId: user.individualId,
              isChecked: e.target.checked
            }
          }
        })
      }
    },
    [
      fetchUsers,
      createEnterpriseSsidUser,
      updateEnterpriseSsidUser,
      ssidUsers,
      enterprise,
      selectedSsid
    ]
  )

  const mapUsers = useMemo(() => {
    const map = new Map()
    map.set('items', [
      { label: t('ssid'), value: 'individualName' },
      {
        label: t('access'),
        component: (row: any) => (
          <CheckboxComponent
            checked={row.access as boolean}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateUser(row, e)}
          />
        )
      }
    ])
    map.set('data', ssidUsers ?? [])
    return map
  }, [ssidUsers])

  useEffect(() => {
    fetchUsers().then()
  }, [fetchUsers])

  useEffect(() => {
    if (ssids && ssids.length > 0) {
      setSelectedSsid(ssids[0].id)
    }
  }, [ssids])

  return (
    <Card sx={{ marginTop: 7 }}>
      <CardContent>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <TitleComponent text={t('ssid_users_list')} variant={'h3'} paddingLeft={12} />
          </Stack>
        </Box>
        <Grid container columns={12} marginY={4}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth size={'small'}>
              <Select
                value={selectedSsid ?? ''}
                onChange={(e: any) => setSelectedSsid(e.target.value)}
              >
                {ssids.map((ssid: any) => {
                  return (
                    <MenuItem key={ssid.id} value={ssid.id}>
                      {ssid.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container columns={4}>
          <Grid item xs={4}>
            <ListSheetComponent data={mapUsers} isLoading={isUsersLoading || isLoading} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
