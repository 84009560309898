import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { Contract, FormItems } from 'api/models'
import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { Link } from 'app/components/link.component'
import { useTranslation } from 'react-i18next'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { formatCurrency } from 'app/utils/format'
import dayjs from 'dayjs'
import { DeleteForever } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { FormList } from 'app/components/form/form-list.component'
import { useFeedback } from 'app/providers/feedback.provider'
import {
  ConsumptionItem,
  ConsumptionItemForm,
  FormProps,
  formSchema
} from 'api/models/forms/consumptions'

const initialSelector = {
  service: '',
  consumptionId: ''
}

export const OptionsAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    getFormItemsWithFilters,
    getContract,
    getContractSelectOptions,
    createContractServices,
    getEnterpriseMembers,
    getIndividual,
    getServiceAvailability,
    searchParams
  } = useFetcher()
  const [contract, setContract] = useState<Contract>({} as Contract)
  const { id } = useParams()
  const { toast, handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [selector, setSelector] = useState(initialSelector)
  const { Accent } = useApp()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [consumptionsTypes, setConsumptionsTypes] = useState([] as ConsumptionItem[])
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['options_types', 'type'],
      ['centers', 'center']
    ])
  )
  const methods = useForm<FormProps>({
    mode: 'onChange',
    resolver: zodResolver(
      formSchema.refine((data) => {
        const items = data.items
        if (items.length === 0) {
          return false
        }
        const invalid = items.find((item) => !item.isValid)
        return invalid === undefined
      })
    ),
    values: {
      contractId: Number(searchParams.get('contract')) || 0,
      owner: 0,
      client: 0,
      center: Number(searchParams.get('center')) || 0,
      items: []
    }
  })
  const { append, remove, fields } = useFieldArray({
    control: methods.control,
    name: 'items'
  })
  const items = useWatch({ name: 'items', control: methods.control })
  const [isFetchingAvailability, setIsFetchingAvailability] = useState<boolean>(false)
  const { isValid, isSubmitting } = methods.formState

  useEffect(() => {
    const subscription = methods.watch(async (value, { name, type }) => {
      if (type && name && type === 'change') {
        if (/\.(begin)$/.exec(name)) {
          const currentIndex = Number(/[0-9]+/.exec(name)![0])
          const item = value.items![currentIndex]
          const serviceId = item!.service!
          const begin = item!.begin.format('YYYY-MM-DD')

          await handleMutation({
            onStart: () => setIsFetchingAvailability(true),
            mutation: getServiceAvailability,
            data: {
              id: serviceId,
              begin: begin
            },
            onSuccess: (response) => {
              if (response.message) {
                methods.setValue(`items.${currentIndex}.isValid`, false, { shouldValidate: true })
                toast({
                  message: t(response.message),
                  variant: 'warning'
                })
              } else {
                methods.setValue(`items.${currentIndex}.isValid`, true, { shouldValidate: true })
              }
            },
            onEnd: () => setIsFetchingAvailability(false)
          })
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [methods.watch, handleMutation, getServiceAvailability, setIsFetchingAvailability, toast])

  const getConsumptionService = useCallback(
    (event: SelectChangeEvent) => {
      setSelector({ consumptionId: '', service: event.target.value })
      setConsumptionsTypes([])
      getContractSelectOptions
        .mutateAsync({
          serviceTypeReference: String(event.target.value),
          id: contract.id
        })
        .then((data: any[]) => {
          const items = data.map((item) => ({
            ...item,
            label:
              event.target.value === 'PARKING'
                ? `${item.label} / ${t('floor')} ${item.floor}`
                : item.label
          }))
          setConsumptionsTypes(items)
          setSelector({
            consumptionId: data.length > 0 ? data[0].id : '',
            service: event.target.value
          })
        })
    },
    [methods, selector, getContractSelectOptions, setConsumptionsTypes]
  )

  const init = useCallback(async () => {
    try {
      if (id) {
        const contract = await getContract.mutateAsync(Number(id))
        setContract(contract)
        methods.setValue('center', contract.centerId)
        methods.setValue('contractId', contract.id)
      }
    } catch {
      toast({
        message: 'error_retrieving_data',
        variant: 'error'
      })
    }
  }, [methods, getEnterpriseMembers, getIndividual, getFormItemsWithFilters, id])

  const fetchOptions = useCallback(async () => {
    if (!contract || !contract.centerId) return
    const optionsData = await getFormItemsWithFilters.mutateAsync({
      filters: Array.from(commonOptions.keys() as any),
      references_filters: {
        options_types: { center: contract.centerId }
      }
    })
    setFormItems(optionsData as FormItems)
  }, [contract])

  useEffect(() => {
    fetchOptions().then()
  }, [contract])

  const handleSubmit = useCallback(
    async (data: FormProps) => {
      if (!contract || !contract.id) return
      await handleMutation({
        confirm: {
          content: t('confirm_add_options')
        },
        mutation: createContractServices,
        data: {
          id: Number(contract.id),
          params: data
        },
        toastSuccess: t('success_create_option'),
        toastError: t('error'),
        onSuccess: () => navigate(`/contracts/${contract.id}`)
      })
    },
    [contract, navigate]
  )

  const addConsumptionService = useCallback(async () => {
    const consumption = consumptionsTypes.find(
      (c: ConsumptionItem) => c.id === Number(selector.consumptionId)
    )

    if (consumption) {
      let isValid = true
      if (selector.service === 'PARKING') {
        setIsFetchingAvailability(true)
        try {
          const response = await getServiceAvailability.mutateAsync({
            id: consumption.id
          })
          if (response.message) {
            isValid = false
            toast({
              message: t(response.message),
              variant: 'warning'
            })
          }
        } finally {
          setIsFetchingAvailability(false)
        }
      }

      append({
        isValid: isValid,
        isFreePrice: consumption.isFreePrice,
        serviceType: selector.service,
        isFreeFees: consumption.isFreeFees,
        customprice: Number(consumption.dailyPrice ?? consumption.price ?? 0),
        customcomment: selector.service === 'CUSTOM' ? '' : undefined,
        customlabel: selector.service === 'CUSTOM' ? '' : undefined,
        customisrecurent: 1,
        reduction: 0,
        label: consumption.label ?? '',
        quantity: 1,
        begin: dayjs.utc().hour(9).startOf('hour'),
        end: null,
        fees: consumption.isFreeFees ? 0 : Number(consumption.commissioningFees),
        price: consumption.dailyPrice ?? consumption.price ?? '0',
        service: consumption.id,
        consumption: consumption
      })
    }
  }, [consumptionsTypes, selector, t, getServiceAvailability, setIsFetchingAvailability])

  useEffect(() => {
    for (let index in items) {
      if (items[index].price !== getPrice(items[index])) {
        methods.setValue(`items.${Number(index)}.price`, getPrice(items[index]))
      }
    }
  }, [methods, items])

  useEffect(() => {
    init().then()
  }, [])

  const totalFees = useMemo((): string => {
    return formatCurrency(
      items.reduce((acc, item) => {
        return acc + Number(item.fees)
      }, 0)
    )
  }, [items])

  const total = useMemo((): string => {
    return formatCurrency(
      items.reduce((acc, item) => {
        return acc + Number(item.price)
      }, 0)
    )
  }, [items])

  const getPrice = useCallback((field: ConsumptionItemForm) => {
    let price = field.customprice
    return price * (1 - field.reduction / 100) * field.quantity
  }, [])

  const cellLabel = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return field.serviceType === 'CUSTOM' ? (
        <ControlledTextField
          control={methods.control}
          name={`items.${index}.customlabel`}
          type={'text'}
        />
      ) : (
        field.label
      )
    },
    [methods]
  )

  const cellFees = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return field.isFreeFees ? (
        <ControlledTextField control={methods.control} name={`items.${index}.fees`} type={'text'} />
      ) : (
        <Typography>{formatCurrency(String(items[index]?.fees))}</Typography>
      )
    },
    [items, methods]
  )

  const cellPrice = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return (
        <>
          {field.isFreePrice && (
            <ControlledTextField
              control={methods.control}
              name={`items.${index}.customprice`}
              type={'text'}
            />
          )}
          <Typography>{formatCurrency(items[index] ? items[index].price : field.price)}</Typography>
        </>
      )
    },
    [methods, items]
  )

  const removeItem = useCallback(
    (_: ConsumptionItemForm, index: number) => {
      remove(index)
    },
    [items, remove]
  )

  const onConsumptionTypeChange = useCallback(
    (event: SelectChangeEvent) => setSelector({ ...selector, consumptionId: event.target.value }),
    [setSelector, selector]
  )

  return (
    <Container>
      <Grid container columns={12} sx={{ marginBottom: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {t('add_contract_options')} |{' '}
            {<Link to={`/contracts/${contract.id}`}>{contract.reference}</Link>}
          </Typography>
          <Typography>{contract.clientName}</Typography>
        </Grid>
      </Grid>

      <FormProvider {...methods}>
        <Grid item xs={11}>
          <Paper sx={{ padding: 4, marginBottom: 4 }}>
            <Grid container columns={12} columnSpacing={4}>
              <Grid item xs={4}>
                <FormControl size={'small'} fullWidth>
                  <InputLabel>{t('service_type')}</InputLabel>
                  <Select
                    label={t('service_category')}
                    value={selector.service}
                    onChange={getConsumptionService}
                  >
                    {formItems.options_types?.values.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size={'small'} fullWidth>
                  <InputLabel>{t('option')}</InputLabel>
                  <Select
                    size={'small'}
                    label={t('option')}
                    fullWidth
                    value={selector.consumptionId}
                    onChange={onConsumptionTypeChange}
                  >
                    {consumptionsTypes.map((item: { id: number; label: string }) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant={'contained'}
                  onClick={addConsumptionService}
                  disabled={isFetchingAvailability}
                >
                  {t('add_option')}
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 4 }} />
            <Grid container columns={24} alignItems={'center'}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  <Accent>{t('fees')}:</Accent> {totalFees}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  <Accent>{t('total')}:</Accent> {total}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  size={'small'}
                  disabled={!isValid || isSubmitting}
                  onClick={methods.handleSubmit(handleSubmit)}
                  variant={'contained'}
                >
                  {t('validate')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <FormList
                  itemsName={'items'}
                  methods={methods}
                  items={fields}
                  rows={[
                    {
                      columns: [
                        {
                          label: t('label'),
                          slug: 'label',
                          type: 'text',
                          cellFormatter: cellLabel
                        },
                        { label: t('quantity'), slug: 'quantity', type: 'textfield' },
                        { label: t('begin'), slug: 'begin', type: 'datetime' },
                        { label: t('discount'), slug: 'reduction', type: 'textfield' },
                        { label: t('fees'), slug: 'fees', type: 'text', cellFormatter: cellFees },
                        {
                          label: t('price'),
                          slug: 'price',
                          type: 'text',
                          cellFormatter: cellPrice
                        },
                        {
                          buttonLabel: DeleteForever,
                          label: '',
                          type: 'button',
                          onClick: removeItem
                        }
                      ]
                    },
                    {
                      displayCondition: (field) => field.serviceType === 'CUSTOM',
                      columns: [
                        {
                          label: t('comment'),
                          slug: 'customcomment',
                          type: 'textfield',
                          props: { colSpan: 4 }
                        },
                        { label: t('price'), slug: 'customprice', type: 'textfield' }
                      ]
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </FormProvider>
    </Container>
  )
}
