import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Container, IconButton, Card, CardContent, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'app/components/link.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ConsumptionContract,
  ConsumptionContractConsumption,
  ConsumptionContractConsumptions
} from 'api/models/consumption-contracts'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { formatCurrency, formatDateWithTime, formatNumbers } from 'app/utils/format'
import { Add, Delete } from '@mui/icons-material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Stack } from '@mui/system'
import { FileText } from 'react-feather'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import { useFeedback } from 'app/providers/feedback.provider'

export const ConsumptionContractView = () => {
  const { id } = useParams()
  const [contract, setContract] = useState<ConsumptionContract>({} as ConsumptionContract)
  const [consumptions, setConsumptions] = useState<ConsumptionContractConsumptions>(
    [] as ConsumptionContractConsumptions
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const [contractServices, setContractServices] = useState<number[]>([])
  const {
    getConsumptionContract,
    getConsumptionContractConsumptions,
    deleteContractService,
    generatePartialContractInvoice
  } = useFetcher()

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!id) return
      const pContract = getConsumptionContract.mutateAsync(Number(id))
      const pConsumptions = getConsumptionContractConsumptions.mutateAsync(Number(id))
      const [contract, consumptions] = await Promise.all([pContract, pConsumptions])
      setContract(contract)
      setConsumptions(consumptions)
    } finally {
      setIsLoading(false)
    }
  }, [id])

  const deleteService = useCallback(
    async (item: ConsumptionContractConsumption) => {
      await handleMutation({
        confirm: {
          content: t('confirm_delete_service')
        },
        mutation: deleteContractService,
        data: {
          contractId: contract.id,
          serviceId: item.id
        },
        onSuccess: () => navigate(0)
      })
    },
    [contract, deleteContractService]
  )

  useEffect(() => {
    fetchData().then()
  }, [id])

  const handleInvoiceToGenerate = useCallback(
    (checked: boolean, item: ConsumptionContractConsumption) => {
      let ids = [...contractServices]
      const index = ids.indexOf(item.id)
      if (checked && index === -1) {
        ids.push(item.id)
      } else if (!checked && index > -1) {
        ids.splice(index, 1)
      }
      setContractServices(ids)
    },
    [consumptions, contractServices, setContractServices]
  )

  const toggleAll = (checked: boolean) => {
    let contractServices: number[] = []
    if (checked) {
      consumptions.forEach((item) => {
        if (!item.canReadInvoice) contractServices.push(item.id)
      })
    }
    setContractServices(contractServices)
  }

  const map = useMemo(() => {
    const map = new Map()
    map.set('items', [
      {
        label: (
          <CheckboxComponent
            checked={contractServices.length > 0}
            onChange={(e) => toggleAll(e.target.checked)}
          />
        ),
        component: (item: ConsumptionContractConsumption) =>
          !item.canReadInvoice && !item.isNonInvoiceable ? (
            <CheckboxComponent
              checked={contractServices.indexOf(item.id) > -1}
              onChange={(e) => handleInvoiceToGenerate(e.target.checked, item)}
            />
          ) : null
      },
      { label: t('type'), value: 'serviceType' },
      { label: t('label'), value: 'label' },
      { label: t('quantity'), value: 'quantity', format: formatNumbers },
      { label: t('price'), value: 'price', format: formatCurrency },
      {
        label: t('date'),
        component: (item: ConsumptionContractConsumption) =>
          `${formatDateWithTime(item.begin)} - ${formatDateWithTime(item.end)}`
      },
      { label: t('invoice'), value: 'invoiceReference', link: '/invoices/:invoiceId' },
      {
        label: t('paid'),
        component: (item: ConsumptionContractConsumption) => (item.isPaid ? t('yes') : t('no'))
      },
      {
        label: '',
        component: (item: ConsumptionContractConsumption) =>
          item.isRemovable && (
            <IconButton
              size="small"
              color={'primary'}
              onClick={async () => {
                await deleteService(item)
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          )
      }
    ])
    map.set('data', consumptions)
    return map
  }, [consumptions, contractServices])

  const generateInvoice = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_generate_invoice')
      },
      mutation: generatePartialContractInvoice,
      data: {
        id: contract.id,
        contractServices: contractServices
      },
      toastSuccess: t('invoice_created_successfully'),
      toastError: t('invoice_created_error'),
      onSuccess: () => navigate(0)
    })
  }, [contract, generatePartialContractInvoice, navigate, contractServices])

  return (
    <Container>
      <>
        <Typography variant="h1">
          {t('consumptions')} |{' '}
          <Link
            to={`${contract.isEnterprise ? '/enterprises' : '/individuals'}/${contract.clientId}`}
          >
            {contract.clientName}
          </Link>
        </Typography>
        <Typography variant="body2">{contract.centerName}</Typography>
      </>
      <Card sx={{ marginTop: 7 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <TitleComponent
                text={t('services_list')}
                variant={'h3'}
                paddingTop={0}
                paddingLeft={12}
              />
              <IconButton
                href={`${contract.isEnterprise ? '/enterprises' : '/individuals'}/${
                  contract.clientId
                }/consumptions/add?center=${contract.centerId}&contract=${contract.id}`}
                size={'small'}
              >
                <Add fontSize="small" />
              </IconButton>
            </Stack>
            <Button
              size="small"
              startIcon={<FileText size={14} />}
              onClick={generateInvoice}
              disabled={contractServices.length === 0}
            >
              {t('generate_invoice')}
            </Button>
          </Stack>
          <ListSheetComponent isLoading={isLoading} data={map} />
        </CardContent>
      </Card>
    </Container>
  )
}
