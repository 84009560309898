import { z } from 'zod'
import { itemListSchema } from './commons'
import dayjs from 'dayjs'

export const happeningSchema = z.object({
  id: z.number(),
  name: z.string(),
  center: z.number(),
  begin: z.string(),
  end: z.string(),
  updatedAt: z.string(),
  publicatedAt: z.string(),
  organizedBy: z.string()
})
export type Happening = z.infer<typeof happeningSchema>
const happeningsListSchema = itemListSchema(happeningSchema)
export type HappeningsList = z.infer<typeof happeningsListSchema>

export const happeningDetailsSchema = z.object({
  name: z.string(),
  id: z.number(),
  place: z.string(),
  centerName: z.string(),
  center: z.number(),
  description: z.string(),
  partnerName: z.string(),
  partnerId: z.number(),
  isActive: z.boolean(),
  banner: z.string(),
  nbUsers: z.number(),
  maxUsers: z.number(),
  publicatedAt: z.string(),
  partnerLink: z.string(),
  begin: z.instanceof(dayjs as any).nullable(),
  end: z.instanceof(dayjs as any).nullable()
})
export type HappeningsDetails = z.infer<typeof happeningDetailsSchema>

export const happeningPartnerSchema = z.object({
  id: z.number(),
  name: z.string(),
  website: z.string(),
  logo: z.string()
})
export type HappeningPartner = z.infer<typeof happeningPartnerSchema>
const happeningsPartnerSchema = itemListSchema(happeningPartnerSchema)
export type HappeningsListPartner = z.infer<typeof happeningsPartnerSchema>

export const createHappeningFormSchema = z.object({
  name: z
    .string()
    .min(1)
    .refine((value) => value.trim().length > 0),
  centers: z.array(z.number()),
  organizedByPost: z.string().optional(),
  place: z
    .string()
    .min(1)
    .refine((value) => value.trim().length > 0),
  publicatedAt: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  description: z.string().optional(),
  nbUsers: z.number({ coerce: true }).optional().nullable(),
  maxUsers: z.number({ coerce: true }).optional().nullable(),
  isActive: z.boolean().optional(),
  begin: z
    .instanceof(dayjs as any)
    .refine(
      (date) =>
        dayjs(date, 'DD/MM/YYYY HH:mm', true).isValid() ||
        dayjs(date, 'MM/DD/YYYY HH:mm', true).isValid()
    ),
  end: z
    .instanceof(dayjs as any)
    .refine(
      (date) =>
        dayjs(date, 'DD/MM/YYYY HH:mm', true).isValid() ||
        dayjs(date, 'MM/DD/YYYY HH:mm', true).isValid()
    ),
  banner: z.string().optional(),
  partnerLink: z.string().optional()
})
export type CreateHappeningForm = z.infer<typeof createHappeningFormSchema>

export const updateHappeningFormSchema = z
  .object({
    name: z.string(),
    place: z.string(),
    centerName: z.string(),
    center: z.number(),
    organizedBy: z
      .object({
        id: z.number(),
        label: z.string()
      })
      .optional(),
    description: z.string().optional(),
    isActive: z.boolean().optional(),
    banner: z.string().optional(),
    nbUsers: z.number({ coerce: true }).optional(),
    maxUsers: z.number({ coerce: true }).optional(),
    partnerLink: z.string().optional().nullable(),
    begin: z
      .instanceof(dayjs as any)
      .refine(
        (date) =>
          dayjs(date, 'DD/MM/YYYY HH:mm', true).isValid() ||
          dayjs(date, 'MM/DD/YYYY HH:mm', true).isValid()
      ),
    end: z
      .instanceof(dayjs as any)
      .refine(
        (date) =>
          dayjs(date, 'DD/MM/YYYY HH:mm', true).isValid() ||
          dayjs(date, 'MM/DD/YYYY HH:mm', true).isValid()
      )
  })
  .refine((data) => {
    return (
      (data.organizedBy?.id !== 0 ? data.partnerLink : true) &&
      data.name.trim() &&
      data.description?.trim() &&
      data.place.trim()
    )
  })

export type UpdateHappening = z.infer<typeof updateHappeningFormSchema>
export const createHappeningPartnerFormSchema = z.object({
  name: z.string().min(2),
  website: z.string().optional(),
  logo: z.string().optional()
})
export type CreateHappeningPartnerForm = z.infer<typeof createHappeningPartnerFormSchema>
