import React, { RefObject } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Box, Button, Divider, Grid, InputAdornment, Paper } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import dayjs from 'dayjs'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { addDiscountSchema, DiscountAddBody } from 'api/models/discounts'
import { Percent } from '@mui/icons-material'
import { TitleComponent } from 'app/components/titles/title.component'

interface IAddDiscountDialogProps {
  dialogRef: RefObject<DialogRef>
  refreshList: (reset: boolean) => Promise<void>
}

export const AddDiscountDialog = function ({ dialogRef, refreshList }: IAddDiscountDialogProps) {
  const { t } = useTranslation()
  const { Discount } = useFetcherV2()

  const method = useForm<DiscountAddBody>({
    mode: 'onChange',
    resolver: zodResolver(addDiscountSchema)
  })

  const { isValid, isSubmitting } = method.formState

  const saveDiscount = useMutationFetcher({
    confirm: { content: t('confirm_add_reduction') },
    mutationKey: ['center discount', 'create'],
    mutationFn: (data: DiscountAddBody) => Discount.create(data),
    toastSuccess: t('add_reduction_success'),
    toastError: t('an_error_occurred'),
    onSuccess: () => {
      dialogRef.current?.close()
      void refreshList(true)
    }
  })

  const handleSubmit = async (data: DiscountAddBody) => {
    saveDiscount(data)
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t('add_reduction')}
      maxWidth="xs"
      actions={
        <>
          <Button variant="outlined" onClick={dialogRef.current?.close}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={method.handleSubmit(handleSubmit)}
          >
            {t('save')}
          </Button>
        </>
      }
    >
      <Grid container spacing={4} columns={1} pt={2}>
        <Grid item xs={1}>
          <ControlledTextField
            label={t('discount_name')}
            name="label"
            control={method.control}
            variant="outlined"
            required={true}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <ControlledDatePicker
            label={t('begin_date')}
            control={method.control}
            required
            name="begin"
            minDate={dayjs().add(1, 'day').startOf('day')}
          />
        </Grid>
      </Grid>

      <Paper variant="outlined" sx={{ mt: 4 }}>
        <Box m={3}>
          <TitleComponent
            text={t('discount_rate')}
            variant={'h4'}
            icon={<Percent color="action" fontSize="small" />}
          />
        </Box>
        <Divider />
        <Box>
          <Box p={4} pt={6}>
            <Grid container spacing={4} columns={1}>
              {['admin', 'siteDirector', 'rxp', 'direction', 'commercial', 'tls'].map((name) => (
                <Grid item xs={12} key={name}>
                  <ControlledTextField
                    required={true}
                    type="number"
                    name={`roles.0.${name}`}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent fontSize={'small'} />
                        </InputAdornment>
                      )
                    }}
                    label={t(name)}
                    control={method.control}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  )
}
