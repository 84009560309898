import { styled } from '@mui/system'
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Link } from 'app/components/link.component'
import React, { ReactNode } from 'react'
import urlHelper from 'app/helpers/url.helper'
import { t } from 'i18next'

const BorderedTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  textWrap: 'nowrap',
  'th, td, tr:last-child td': {
    border: `1px solid ${theme.palette.divider}`
  }
}))

type BorderListColumns<T extends object> = {
  key: keyof T
  label: string
  link?: string
  valueFormatter?: (data: T) => ReactNode
}

interface IBorderListProps<T extends object> {
  columns: BorderListColumns<T>[]
  data: T[]
  keyBy?: keyof T
  isLoading: boolean
}
export const BorderedListComponent = function <T extends object>({
  data,
  columns,
  keyBy,
  isLoading
}: IBorderListProps<T>) {
  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} padding={2}>
        <CircularProgress />
      </Box>
    )
  }

  if (data.length === 0) {
    return (
      <Box display={'flex'} justifyContent={'center'} padding={2}>
        {t('error_loading_data')}
      </Box>
    )
  }

  return (
    <TableContainer
      component={Box}
      paddingX={6}
      sx={{ boxSizing: 'border-box', maxHeight: 400, overflow: 'auto' }}
    >
      <BorderedTable size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              return <TableCell key={index}>{col.label}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any, index: number) => (
            <TableRow key={keyBy ? item[keyBy] : index}>
              {columns.map((col, indexCol) => {
                return (
                  <TableCell key={`${keyBy ? item[keyBy] : index}-col-${indexCol}`}>
                    {col.valueFormatter ? (
                      col.valueFormatter(item)
                    ) : col.link ? (
                      <Link to={urlHelper(col.link, item)}>{item[col.key]}</Link>
                    ) : (
                      item[col.key]
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </BorderedTable>
    </TableContainer>
  )
}
